import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslate } from '../translate/translate';
import "./mini-game.scss"
import { mgOver, getTimeServer, getMgAvailable } from '../requests'
import mgImage from './mg_new.png';
import Alert from '../alerts';

import ContImage from './cont.png';
import Trap1Image from './trap1.png';
import Trap2Image from './trap2.png';
import Trap3Image from './trap3.png';

import Info1Image from './mg-info1.png';

import BackImage from '../port/back.png';
import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';

const Modal = ({ children, onClose }) => {
    const t = useTranslate();
    return ReactDOM.createPortal(
        <div className="mg-overlay" onClick={onClose}>
            <div className="mg-content" onClick={(e) => e.stopPropagation()}>
                {children}
                <button className="mg-ok" onClick={onClose}>{t("ok")}</button>
            </div>
        </div>,
        document.body
    );
};

const InfoModal = ({ children, onClose }) => {
    const t = useTranslate();
    return ReactDOM.createPortal(
        <div className="mg-info-overlay" onClick={onClose}>
            <div className="mg-info-content" onClick={(e) => e.stopPropagation()}>
                {children}
                <button className="mg-info-ok" onClick={onClose}>{t("ok")}</button>
            </div>
        </div>,
        document.body
    );
};

const MiniGame = () => {
    const t = useTranslate();
    const [serverTime, setServerTime] = useState(null); // Сохраняем серверное время
    const [remainingTime, setRemainingTime] = useState(0); // Оставшееся время
    const [openGame, setOpenGame] = useState(false);
    const [activeGroup, setActiveGroup] = useState(0);
    const [clickedCells, setClickedCells] = useState({});
    const [prizeMoney, setPrizeMoney] = useState(0);
    const [prizeFuel, setPrizeFuel] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [mgTimer, setMgTimer] = useState(null);
    const [hasClickedCell, setHasClickedCell] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [trapNumber, setTrapNumber] = useState(null);
    const [isInfo, setIsInfo] = useState(false);
    const [infoModalContent, setInfoModalContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingOpenGame, setLoadingOpenGame] = useState(false);
    const [alert, setAlert] = useState(null);

    const defalult_prize_money = window.lvl * 0.05;
    const defalult_prize_fuel = window.lvl * 0.1;
    const multipliers = [1.00, 1.25, 1.55, 1.90, 2.45, 3.85, 7.25, 18.15, 45.55, 122.50, 350.00];

    const serverTimeRef = useRef(null);

    // Функция для получения времени с сервера
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        setServerTime(serverTimeMs);
        serverTimeRef.current = serverTimeMs; // Сохраняем в ref для обновлений каждую секунду
    };

    useEffect(() => {
        let tickIntervalId;

        fetchServerTime();

        tickIntervalId = setInterval(() => {
            if (serverTimeRef.current) {
                serverTimeRef.current += 1000;
                setServerTime(serverTimeRef.current);
            }
        }, 1000);

        return () => {
            clearInterval(tickIntervalId);
        };
    }, []);

    useEffect(() => {
        const mgTimerDate = new Date(window.mg_time);
        setMgTimer(mgTimerDate.getTime());
    }, [window.mg_time]);

    useEffect(() => {
        const calculateRemainingTime = () => {
            const timeLeft = Math.max(0, mgTimer - serverTime);
            setRemainingTime(timeLeft);
        };

        if (mgTimer && serverTime) {
            calculateRemainingTime();
            const interval = setInterval(calculateRemainingTime, 1000);
            return () => clearInterval(interval);
        } else {
            setRemainingTime(0);
        }
    }, [mgTimer, serverTime]);

    useEffect(() => {
        if (openGame && activeGroup < 11) {
            const interval = setInterval(() => {
                setActiveIndex((prevIndex) => (prevIndex - 1 + 5) % 5);
            }, 500);

            return () => clearInterval(interval);
        }
    }, [openGame, activeGroup]);

    const renderCells = () => {
        const cells = Array.from({ length: 55 }, (_, index) => {
            const group = Math.floor(index / 5);
            const isActive = group === activeGroup;
            const isClicked = clickedCells[index] !== undefined;
            const isAnimated = isActive && index % 5 === activeIndex;

            return (
                <button
                    key={index}
                    className={`mini-game-cell ${isAnimated ? 'active' : ''}`}
                    disabled={!isActive || isClicked}
                    onClick={() => cellClick(index)}
                >
                    {isClicked ? (
                        <img src={clickedCells[index]} alt="" />
                    ) : isActive ? (
                        '?'
                    ) : (
                        ''
                    )}
                </button>
            );
        });

        return cells.reverse();
    };

    const gameOver = (trap, passed) => {
        setLoading(true);
        if (trap) {
            mgOver(window.tgID, trap, 0)
                .then(mgOverResponse => {
                    if (mgOverResponse === 'not-available') {
                        fetchServerTime();
                        setLoading(false);
                        setAlert('mg-not-available');
                    } else if (mgOverResponse) {
                        window.mg_time = mgOverResponse.mg_time;
                        setMgTimer(mgOverResponse.mg_time);
                        setModalContent(
                            <>
                                <span className="mg-modal-title">
                                    {t("fail")}
                                </span>
                                <div className="mg-message">
                                    <span className="mg-message1">{t("mg1")}</span>
                                    <span className="mg-message2">{t("mg2")}</span>
    
                                    <div className="mg-res-box">
                                        <div className="mg-trap-img">
                                            <img src={trapNumber ? trapNumber : Trap1Image} alt="Trap" />
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                        setIsGameOver(true);
                    }
                })
                .catch(() => {
                    setAlert('error');
                    setLoading(false);
                });
        } else {
            const resources = new Array(14).fill(0);

            if (passed) {
                resources[0] = parseFloat((defalult_prize_money * multipliers[10]).toFixed(2));
                resources[1] = parseFloat((defalult_prize_fuel * multipliers[10]).toFixed(2));
            } else {
                resources[0] = parseFloat(prizeMoney);
                resources[1] = parseFloat(prizeFuel);
            }

            mgOver(window.tgID, trap, resources)
                .then(mgOverResponse => {
                    if (mgOverResponse === 'not-available') {
                        fetchServerTime();
                        setLoading(false);
                        setAlert('mg-not-available');
                    } else if (mgOverResponse) {
                        window.mg_time = mgOverResponse.mg_time;
                        setMgTimer(mgOverResponse.mg_time);
                        setModalContent(
                        <>
                            <span className="mg-modal-title">
                                {t("succes")}
                            </span>
                            <div className="mg-message">
                                <span className="mg-message1">{t("mg3")}</span>
                                <span className="mg-message2">{t("mg2")}</span>

                                <div className="mg-res-box">
                                    <div className="mg-res-money">
                                        <div className="mg-res-money-img">
                                            <img src={Resource1Image} alt="Money" />
                                        </div>
                                        <div className="mg-res-money-amount">{passed ? ((defalult_prize_money * multipliers[10]).toFixed(2)) : prizeMoney}</div>
                                    </div>
                                    <div className="mg-res-fuel">
                                        <div className="mg-res-fuel-img">
                                            <img src={Resource2Image} alt="Money" />
                                        </div>
                                        <div className="mg-res-fuel-amount">{passed ? ((defalult_prize_fuel * multipliers[10]).toFixed(2)) : prizeFuel}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                    setIsGameOver(true);
                }})
                .catch(error => {
                    console.error('Error in gameOver:', error);
                    setLoading(false);
                });
        }
    };

    const cellClick = (index) => {
        const group = Math.floor(index / 5);
        if (group === activeGroup) {
            const newClickedCells = { ...clickedCells };
            const trapCount = getTrapCount(group);
            const trapIndices = new Set();

            while (trapIndices.size < trapCount) {
                trapIndices.add(Math.floor(Math.random() * 5));
            }

            for (let i = group * 5; i < (group + 1) * 5; i++) {
                if (trapIndices.has(i % 5)) {
                    newClickedCells[i] = getRandomTrapImage();
                    if (i === index) {
                        
                        gameOver(true, false);
                        return;
                    }
                } else {
                    newClickedCells[i] = ContImage;
                }
            }

            setClickedCells(newClickedCells);
            setActiveGroup(group + 1);
            setHasClickedCell(true);

            setPrizeMoney((defalult_prize_money * multipliers[group]).toFixed(2));
            setPrizeFuel((defalult_prize_fuel * multipliers[group]).toFixed(2));

            if (group + 1 === 11) {
                gameOver(false, true);
            }
        }
    };

    const getRandomTrapImage = () => {
        const trapImages = [Trap1Image, Trap2Image, Trap3Image];
        const randomIndex = Math.floor(Math.random() * trapImages.length);
        setTrapNumber(trapImages[randomIndex]);
        return trapImages[randomIndex];
    };

    const getTrapCount = (group) => {
        if (group === 0) return 0;
        if (group >= 1 && group <= 3) return 1;
        if (group >= 4 && group <= 6) return 2;
        if (group >= 7 && group <= 9) return 3;
        if (group === 10) return 4;
        return 0;
    };

    const closeGame = () => {
        setOpenGame(false);
        setActiveGroup(0);
        setClickedCells({});
        setPrizeMoney(0);
        setPrizeFuel(0);
        setActiveIndex(0);
        setHasClickedCell(false);
        setIsGameOver(false);
    };

    const openInfo = () => {
        setInfoModalContent(
            <>
                <img src={Info1Image} alt="Info" />
            </>
        );
        setIsInfo(true);
    };

    const closeInfo = () => {
        setIsInfo(false);
    };

    const formatTime = (timeLeft) => {
        const totalSeconds = Math.floor(timeLeft / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const openGameFunc = () => {
        setLoadingOpenGame(true);

        getMgAvailable(window.tgID)
            .then((available) => {
                if (available === 'available') {
                    setLoadingOpenGame(false);
                    setOpenGame(true);
                } else if (available === 'not-available') {
                    // Время для игры не пришло
                    fetchServerTime();
                    setLoadingOpenGame(false);
                    setAlert('mg-not-available');
                }
            })
            .catch(() => {
                setLoadingOpenGame(false);
                setAlert('error');
            });
    };

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert} /> : null}

            {isGameOver && (
                <Modal onClose={() => closeGame()}>
                    {modalContent}
                </Modal>
            )}

            {isInfo && (
                <InfoModal onClose={() => closeInfo()}>
                    {infoModalContent}
                </InfoModal>
            )}

            {openGame ? (
                <>
                    <div className="mini-game-overlay">
                        <div className="mini-game-header">
                            <button className="mini-game-back" onClick={closeGame}>
                                <img src={BackImage} alt="Back" />
                            </button>
                            <span className="mini-game-title">
                                {t("mg-title")}
                            </span>
                            <button className="mini-game-info" onClick={openInfo}>i</button>
                        </div>
                        <div className="mini-game-box">
                            <div className="mini-game-multiplies">
                                {multipliers.slice().reverse().map((multiplier, index) => (
                                    <div key={index} className="mini-game-multiplier">
                                        x{multiplier}
                                    </div>
                                ))}
                            </div>
                            <div className="mini-game-grid">
                                {renderCells()}
                            </div>
                        </div>
                        <div className="mini-game-result">
                            <div className="mini-game-prize">
                                <span className="mini-game-prize-title">{t("wins")}</span>
                                <div className="mini-game-prize-resources">
                                    <div className="mini-game-prize-money">
                                        <div className="mini-game-prize-money-img">
                                            <img src={Resource1Image} alt="Money" />
                                        </div>
                                        <div className="mini-game-prize-money-amount">{prizeMoney}</div>
                                    </div>
                                    <div className="mini-game-prize-fuel">
                                        <div className="mini-game-prize-fuel-img">
                                            <img src={Resource2Image} alt="Fuel" />
                                        </div>
                                        <div className="mini-game-prize-fuel-amount">{prizeFuel}</div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className={`mini-game-prize-button ${!hasClickedCell || loading ? 'disabled' : ''}`}
                                onClick={() => gameOver(false, false)}
                                disabled={!hasClickedCell || loading}
                            >
                                {loading ? (
                                    <div className="loading-animation">
                                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                ) :
                                    <span>{t('take')}</span>
                                }
                            </button>
                        </div>
                    </div>
                </>
            ) : null}

            <button
                className="mini-game-button"
                onClick={() => openGameFunc()}
                disabled={mgTimer > serverTime || loadingOpenGame}
            >
                <img src={mgImage} alt="Mini Game" />
                {remainingTime > 0 ? (
                    <div className="mini-game-timer">
                        {formatTime(remainingTime)}
                    </div>
                ) : (
                    <>
                        {!loadingOpenGame ? 
                        <span className="mini-game-name">{t("mini-game")}</span> : 
                        <div className="loading-animation">
                            <svg className="spinner" strokeWidth="65px" height="75%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                        }
                        
                    </>
                )}
            </button>
        </>
    );
};

export default MiniGame;