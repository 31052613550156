
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './clans.scss';
import { useTranslate } from '../translate/translate';
import ClansImage from '../navigation/clans.png';
import BackImage from '../port/back.png';

const Clans = ({ flotilias, setFlotilias }) => {
    const t = useTranslate();
    
    return (
        <>
        {flotilias && (
            ReactDOM.createPortal(
                <div className="clans-overlay">

                    <div className="clans-header">
                        <button className="clans-back-button" onClick={() => setFlotilias(false)}>
                            <img src={BackImage} alt="Back" />
                        </button>
                        <span className="clans-title">{t('title-clans')}</span>
                    </div>

                    <div className="clans-info-box">
                        <h1>{t('flot-info-1')}</h1>
                        <h1>{t('flot-info-2')}</h1>
                        <div className="clans-icon-box">
                            <img src={ClansImage} alt="Flotilias" />
                        </div>
                        <span>{t('flot-info-3')}</span>
                        <span>{t('flot-info-4')}</span>
                        <span>{t('flot-info-5')}</span>
                        <li>{t('flot-info-6')}</li>
                        <li>{t('flot-info-7')}</li>
                        <li>{t('flot-info-8')}</li>
                        <div className="clans-buttons">
                            <button>{t('join')}</button>
                            <button>{t('create')}</button>
                        </div>
                    </div>
                </div>,
                document.getElementById('clans-portal')
            )
        )}    
        </>
    );
};

export default Clans;