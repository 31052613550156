import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './warnings.scss';
import { useTranslate } from './translate/translate';

import ErrorIcon from './error.png';

const Warning = ({ warning, setWarning }) => {
    const t = useTranslate();
    const [fadeClass, setFadeClass] = useState('fade-in');

    useEffect(() => {
        const timer = setTimeout(() => {
            setFadeClass('fade-out');
            setTimeout(() => {
                setWarning(false);
            }, 300);
        }, 3000);

        return () => clearTimeout(timer);
    }, [warning]);

    return (
        <>
            {warning && ReactDOM.createPortal(
                <div className={`warning-alert-box ${fadeClass}`}>
                    <img src={ErrorIcon} alt="Error" />
                    <span>{t("warning-wallet")}</span>
                </div>,
                document.body
            )}
        </>
    );
};

export default Warning;