import React, { useState, useEffect, useRef } from 'react';
import "./upgrade.scss";
import ReactDOM from 'react-dom';
import { changeResources, changeShipLvl } from '../../requests';
import { format, formatCapacity } from '../../format-number';
import { useTranslate } from '../../translate/translate';
import Alert from '../../alerts'
import InfoShips from './info';

import capacityImage from './cont-icon.png'
import speedImage from './speed.png'
import UpgradeImage from '../cont/upgrade_yes.png';

import Resource1Image from '../../resources/1.png';
import Component1Image from '../../components/1.png';
import Component2Image from '../../components/2.png';
import Component3Image from '../../components/3.png';
import Component4Image from '../../components/4.png';
import Component5Image from '../../components/5.png';
import Component6Image from '../../components/6.png';
import Component7Image from '../../components/7.png';
import Component8Image from '../../components/8.png';
import Component9Image from '../../components/9.png';
import Component10Image from '../../components/10.png';
import Component11Image from '../../components/11.png';
import Component12Image from '../../components/12.png';
import Component13Image from '../../components/13.png';
import Component14Image from '../../components/14.png';
import Component15Image from '../../components/15.png';
import Component16Image from '../../components/16.png';
import Component17Image from '../../components/17.png';
import Component18Image from '../../components/18.png';
import Component19Image from '../../components/19.png';
import Component20Image from '../../components/20.png';
import Component21Image from '../../components/21.png';

const resourceImages = {
    1: Resource1Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const UpgradeModal = ({ modalUpgrade, setModalUpgrade, config, selectedShip, shipImages, updateShips }) => {
    const t = useTranslate();
    const [alert, setAlert] = useState(null);
    const [showPyro, setShowPyro] = useState(false);
    const [info, setInfo] = useState(false);
    const [currentShip, setCurrentShip] = useState(selectedShip);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCurrentShip(selectedShip);
    }, [selectedShip]);

    const ship = config.ships.find(ship => ship.type === currentShip.type);
    const currentLevel = ship.levels.find(level => level.level === currentShip.lvl);
    const nextLevel = ship.levels[ship.levels.indexOf(currentLevel) + 1];

    const userResources = window.resources.map((value, index) => value - window.resources_market[index]);
    const userComponents = window.components.map((value, index) => value - window.components_market[index]);

    const upgradeShip = () => {
        setLoading(true);
        if (currentLevel.level != 10) {
            const moneyCost = Array(14).fill(0);
    
            const resourceItem = currentLevel.requirements.find(item => item.resourceId !== undefined);
            if (resourceItem) {
                moneyCost[0] = resourceItem.amount;
            }
    
            const componentsCost = Array(21).fill(0);
            currentLevel.requirements.forEach(item => {
                if (item.componentId !== undefined) {
                    componentsCost[item.componentId - 1] = item.amount;
                }
            });
    
            const isMoney = moneyCost.every((amount, index) => amount <= 0 || amount <= userResources[index]);
            const isComponents = componentsCost.every((amount, index) => amount <= 0 || amount <= userComponents[index]);
    
            if (isMoney) {
                if (isComponents) {
                    changeShipLvl(window.tgID, selectedShip.id, moneyCost, componentsCost)
                        .then(() => {
                            setCurrentShip(prevShip => ({ ...prevShip, lvl: prevShip.lvl + 1 }));
                            updateShips();
    
                            setAlert('ship-lvlup');
    
                            setShowPyro(true);
                            setTimeout(() => {
                                setShowPyro(false);
                            }, 1000);
                        })
                        .catch(error => {
                            setAlert('error');
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setAlert('not-components-upgrade');
                    setLoading(false);
                }
            } else {
                setAlert('not-money-upgrade');
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <>      
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null} 
            {info ? <InfoShips info={info} setInfo={setInfo} currentShip={currentShip} config={config} shipImages={shipImages}/> : null}
            {modalUpgrade ? (
                ReactDOM.createPortal(
                    <div className="upgrade-ship-overlay" onClick={() => setModalUpgrade()}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <img className="image" src={shipImages[selectedShip.type]} alt={selectedShip.type} />
                            <div className="ship-name">{selectedShip.name}</div>
                            <button className="info-button" onClick={() => setInfo(true)}>i</button>
                            
                            <div className='lvls-box'>
                                <div className='current-lvl'>
                                    <div className='header-lvl'>
                                        <span>{t('current')}</span>
                                        <div className='lvl'>{t('lvl')} {currentLevel.level}</div>
                                    </div>

                                    <div className='stat'>
                                        <div className='capacity'>
                                            <div className='image'><img src={capacityImage} alt="Capacity" /></div>
                                            <span>{currentLevel.capacity}</span>
                                        </div>
                                        <div className='speed'>
                                            <div className='image'><img src={speedImage} alt="Speed" /></div>
                                            <span>+{currentLevel.speed}%</span>
                                        </div>
                                    </div>
                                </div>

                                <img className='arrow' src={UpgradeImage} alt="Upgrade" />

                                <div className='next-lvl'>
                                    <div className='header-lvl'>
                                        <span>{t('next-info')}</span>
                                        <div className='lvl'>{t('lvl')} {nextLevel.level}</div>
                                    </div>

                                    <div className='stat'>
                                        <div className='capacity'>
                                            <div className='image'><img src={capacityImage} alt="Capacity" /></div>
                                            <span>{nextLevel.capacity}</span>
                                        </div>
                                        <div className='speed'>
                                            <div className='image'><img src={speedImage} alt="Speed" /></div>
                                            <span>+{nextLevel.speed}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {showPyro && (
                                <div className="pyro">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div>
                            )}

                            <div className="ship-upgrade-box">
                                <div className="elements">
                                    {currentLevel.requirements.map((req, index) => (
                                        <div key={index} className="element">
                                            <div className="image">
                                                {req.resourceId ? 
                                                    <img src={resourceImages[req.resourceId]} alt={`Resource ${req.resourceId}`} /> :
                                                    <img src={componentImages[req.componentId]} alt={`Component ${req.componentId}`} />
                                                }
                                            </div>
                                            {req.resourceId ? <span>{format(req.amount)}</span> : <span>{formatCapacity(req.amount)}</span> }
                                        </div>
                                    ))}
                                </div>
                                <button className={`upgrade-button ${(loading) ? 'disabled' : ''}`} onClick={() => upgradeShip()} >
                                    {loading ? (
                                        <div className="loading-animation">
                                            <svg className="spinner" strokeWidth="65px" height="20%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    ) : 
                                        <span>{t('upgrade')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default UpgradeModal;