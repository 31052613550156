import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './soon.scss';
import { useTranslate } from '../translate/translate';

const SoonAlert = ({ soonAlert, setSoonAlert }) => {
    const t = useTranslate();
    const [fadeClass, setFadeClass] = useState('fade-in');

    useEffect(() => {
        const timer = setTimeout(() => {
            setFadeClass('fade-out');
            setTimeout(() => {
                setSoonAlert(null);
            }, 300);
        }, 3000);

        return () => clearTimeout(timer);
    }, [soonAlert]);

    return (
        <>
            {soonAlert && ReactDOM.createPortal(
                <div className={`soon-box ${fadeClass} ${soonAlert.page === 'ship' ? 'ship' : soonAlert.page === 'header' ? 'header' : soonAlert.page === 'rig-soon' ? 'rig-soon' : ''}`}
                    style={{
                        left: `${soonAlert.x}px`,
                        top: `${soonAlert.y}px`,
                        pointerEvents: 'none',
                    }}
                >
                    {t('soon')}
                </div>,
                document.body
            )}
        </>
    );
};

export default SoonAlert;