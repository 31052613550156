
import React, { useState, useEffect } from 'react';
import './buy-tab.scss';
import { useTranslate } from '../translate/translate';
import { toUserFriendlyAddress } from '@tonconnect/sdk';
import { getViewsLot, foundLots, changeWallet, getUserInfo } from '../requests'
import { format, formatMarket } from '../format-number';
import TonImage from './ton.png';
import Warning from '../warnings';
import Alert from '../alerts';
import ModalBuy from './buy-modal';
import config_store from '../port/store/config_store.json';

import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';

const resourcesName = {
    1: 'Money',
    2: 'Fuel',
    3: 'Spares',
    4: 'Metal',
    5: 'Instruments',
    6: 'Plastic',
    7: 'Rubber',
    8: 'Paint',
    9: 'Сondenser',
    10: 'Glass',
    11: 'Diode',
    12: 'Battery',
    13: 'Сooler',
    14: 'Microchips'
};

const componentsName = {
    1: 'Winch',
    2: 'Drill',
    3: 'Angle grinder',
    4: 'Hose',
    5: 'Connector',
    6: 'Cable',
    7: 'Lifting mechanism',
    8: 'Electrical panel',
    9: 'Glow plug',
    10: 'Welding machine',
    11: 'Solar panel',
    12: 'Stabiliser',
    13: 'LED panel',
    14: 'Porthole',
    15: 'Generator',
    16: 'Loader',
    17: 'Bathyscaphe',
    18: 'Crane',
    19: 'Boat',
    20: 'Helicopter',
    21: 'Robot'
};

const BuyTab = ({ resourceImages, componentImages, resourceMinimum }) => {
  const t = useTranslate();
  const [tonConnectUI] = useTonConnectUI();
  const [isConnected, setIsConnected] = useState(false);
  const wallet = useTonAddress(false);
  const [tab, setTab] = useState('resources');
  const [selectElement, setSelectElement] = useState(1);
  const [loadList, setLoadList] = useState(false);
  const [items, setItems] = useState([]);
  const componentsMinimum = 1;
  const [buyingLot, setBuyingLot] = useState([]);
  const [warning, setWarning] = useState(false);
  const [buyModal, setBuyModal] = useState(null);
  const [alert, setAlert] = useState(null);
  const [loadingItem, setLoadingItem] = useState([]);

  const fetchItems = async () => {
    setLoadList(true);
    try {
      const respfoundLots = await foundLots(tab, selectElement);
      setItems(respfoundLots);
    } finally {
      setLoadList(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [selectElement, tab]);

  useEffect(() => {
    const checkWalletConnection = async () => {
      const isConnected = tonConnectUI.connected;
      if (isConnected) {
        setIsConnected(true); // Кошелек подключен
        await changeWallet(window.tgID, wallet); // Присваивается window.wallet
      } else {
        setIsConnected(false); // Кошелек не подключен
      }
    };

    checkWalletConnection();
  }, [tonConnectUI.connected]);

  const WalletAddress = (rawAddress) => {
    const userFriendlyAddress = toUserFriendlyAddress(rawAddress);
    const formattedAddress = `${userFriendlyAddress.slice(0, 4)}...${userFriendlyAddress.slice(-4)}`;
    return formattedAddress;
  };

  const calculatePrice = (type, price, value, number) => {  
    const result = (price / value * (type === 'resources' ? resourceMinimum[number] : componentsMinimum)).toFixed(5);
    return parseFloat(result).toString();
  };

  const handleBuy = async (item) => {
    if (window.wallet) {
      setBuyingLot(item.id);
      const viewsStatus = await getViewsLot(item.id);
      await getUserInfo(window.tgID);
      if (viewsStatus) {
        setAlert('buy-error');
        setBuyingLot(null);
      } else {
        // Проверка на склад
        const storeCapacity = config_store.store.find(store => store.lvl === window.lvl_store).capacity[0];
        if (item.type == 'resources') {
          const capacity = storeCapacity.resources;
          if ((window.resources[item.number-1] + item.value) <= capacity[item.number-1]) {
            setBuyModal(item);
          } else {
            setAlert('component-full');
            setBuyingLot(null);
          }
        } else if (item.type == 'components') {
          const capacity = storeCapacity.components;
          if ((window.components[item.number-1] + item.value) <= capacity[item.number-1]) {
            setBuyModal(item);
          } else {
            setAlert('component-full');
            setBuyingLot(null);
          }
        } 
      }
    } else {
      if (!isConnected) { await tonConnectUI.openModal(); }
    }
  };

  const changeTab = (tab) => {
    setTab(tab)
    setSelectElement(1);
  };

  return (
    <>
      {warning ? <Warning warning={warning} setWarning={setWarning}/> : null}
      {alert ? <Alert alert={alert} setAlert={setAlert}/> : null} 
      {buyModal ? <ModalBuy buyModal={buyModal} setBuyModal={setBuyModal} resourceImages={resourceImages}
        componentImages={componentImages} getViewsLot={getViewsLot} setAlert={setAlert}
        resourcesName={resourcesName} componentsName={componentsName} setBuyingLot={setBuyingLot}
        tonConnectUI={tonConnectUI} fetchItems={fetchItems} setItems={setItems} /> : null} 

      <div className='market-buy'>
      <div className='market-buy-store'>
        <div className='buy-switch-box'>
          <button 
              className={`switch-button1 ${tab === 'resources' ? 'active' : ''}`} 
              onClick={() => changeTab('resources')}
              disabled={tab === 'resources'}
          >
              {t('resources')}
          </button>
          <button 
              className={`switch-button2 ${tab === 'components' ? 'active' : ''}`} 
              onClick={() => changeTab('components')}
              disabled={tab === 'components'}
          >
              {t('components')}
          </button>
        </div>

        <div className='store'>
          {tab === 'resources' ? (
            Object.keys(resourceImages).map(key => (
              <button 
                key={key} 
                className={`element-button ${selectElement == key ? 'selected' : ''}`}
                onClick={() => setSelectElement(key)}
                disabled={selectElement === key}
              >
                <img src={resourceImages[key]} alt={resourcesName[key]} />
              </button>
            ))
          ) : (
            Object.keys(componentImages).map(key => (
              <button 
                key={key} 
                className={`element-button ${selectElement == key ? 'selected' : ''}`}
                onClick={() => setSelectElement(key)}
                disabled={selectElement === key}
              >
                <img src={componentImages[key]} alt={`Component ${key}`} />
              </button>
            ))
          )}
        </div>
      </div>

      <div className='buy-list-box'>
          {!loadList ? (
            <>
            {items.length > 0 ? (
              items.map(item => (
                <div key={item.id} className="item">
                  <div className="img-box">
                    <img src={item.type === 'resources' ? resourceImages[((item.number - 1 + 14) % 14) + 1] : componentImages[((item.number - 1 + 21) % 21) + 1]} alt={item.type} />
                    <div className="value-box">
                      <span>
                        {formatMarket(item.value)}
                      </span>
                    </div>
                  </div>
                  <div className="info-box">
                    <div className="seller-box">
                      <span className="title">{t('seller')}</span>
                      <span className="seller">{WalletAddress(item.wallet_seller)}</span>
                    </div>
                    <div className="price-box">
                      <span className="title">{t('price')} ({item.type === 'resources' ? format(resourceMinimum[item.number]) : componentsMinimum})</span>
                      <div className="price">
                        <span> {calculatePrice(item.type, item.price, item.value, item.number)}</span>
                        <img src={TonImage} alt="TON" />
                      </div>
                      
                    </div>

                    <button 
                      className={`buy-button ${(item.wallet_seller === window.wallet) || item.id === buyingLot ? 'disabled' : ''}`}
                      disabled={(item.wallet_seller === window.wallet) || item.id === buyingLot}
                      onClick={() => handleBuy(item)}
                    >
                      {!(item.id === buyingLot) ? (
                        <>
                          <img src={TonImage} />
                          {item.price}
                        </>
                      ) : (
                        <div className="loading-animation-buying">
                          <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                          </svg>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <span className="no-lots-message">{t('no-lots-message')}</span>
            )}
            </>
          ) : (
            <div className="loading-animation">
              <svg className="spinner" strokeWidth="65px" height="95%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
              </svg>
            </div>
          )}
      </div>

    </div>
    </>
  );
};

export default BuyTab;