
export function format(number) {
  if (number < 10) {
    return number.toFixed(2);
  } else if (number < 100) {
    return number.toFixed(1);
  }  else if (number < 1000) {
    return number.toFixed(0);
  } else if (number < 10000) {
    return `${(number / 1000).toFixed(2)}k`;
  } else if (number < 100000) {
    return `${(number / 1000).toFixed(1)}k`;
  } else if (number < 1000000) {
    return `${Math.floor(number / 1000)}k`;
  } else if (number < 10000000) {
    return `${(number / 1000000).toFixed(2)}M`;
  } else if (number < 100000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  } else {
    return `${Math.floor(number / 1000000)}M`;
  }
}

export function formatCapacity(number) {
  if (number < 1000) {
    return number.toFixed(0);
  } else if (number < 10000) {
    return `${(number / 1000).toFixed(2)}k`;
  } else if (number < 100000) {
    return `${(number / 1000).toFixed(1)}k`;
  } else if (number < 1000000) {
    return `${(number / 1000).toFixed(0)}k`;
  } else if (number < 1000000000) {
    return `${(number / 1000000).toFixed(0)}M`;
  }
}

export function formatMarket(number) {
  if (number < 1000) {
    return `${number.toFixed(0)}`;
  } else if (number < 1000000 && number >= 1000) {
    return `${(number / 1000).toFixed(1)}k`;
  } else if (number < 1000000000) {
    return `${(number / 1000000).toFixed(0)}M`;
  }
}