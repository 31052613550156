
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

// Импортируйте ваши переводы
import enTranslation from '../locales/en.json';
import ruTranslation from '../locales/ru.json';
import thaiTranslation from '../locales/thai.json';

const resources = {
  en: {
    translation: enTranslation
  },
  ru: {
    translation: ruTranslation
  },
  thai: {
    translation: thaiTranslation
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Язык по умолчанию
    interpolation: {
      escapeValue: false
    }
  });

export const useTranslate = () => {
    const { t } = useTranslation();
    return t;
};

export default i18n;