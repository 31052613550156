import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './event_quest_modal.scss';
import { useTranslate } from '../../translate/translate';
import { changeEventData } from '../../requests'

import HODLImageQuest from './hodl_quest.jpg'
import tickImage from './tick.png'

const trueAnswers = {
    'HODL': {
        0: 2,
        1: 1,
        2: 2,
        3: 3,
        4: 1
    }
};

const EventQuestModal = ({ eventQuestModal, setEventQuestModal, sendData, handleSend2 }) => {
    const t = useTranslate();
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [answerResult, setAnswerResult] = useState(null);

    const [animatePoint, setAnimatePoint] = useState(false);
    const intermediateValue = useRef(null);

    const HODL = () => {
        return (
            <>
                <img className='event-image' src={HODLImageQuest} alt="HODL, FUCK!" />
                <span className='event-title'>{
                    window.event_voyages[0] == 0 ? t('hodl-quest1') :
                    window.event_voyages[0] == 1 ? t('hodl-quest2') : 
                    window.event_voyages[0] == 2 ? t('hodl-quest3') :
                    window.event_voyages[0] == 3 ? t('hodl-quest4') :
                    window.event_voyages[0] == 4 ? t('hodl-quest5') : null
                }</span>
                <span className='event-question'>{
                    window.event_voyages[0] == 0 ? t('hodl-question1') :
                    window.event_voyages[0] == 1 ? t('hodl-question2') : 
                    window.event_voyages[0] == 2 ? t('hodl-question3') :
                    window.event_voyages[0] == 3 ? t('hodl-question4') :
                    window.event_voyages[0] == 4 ? t('hodl-question5') : null
                }</span>
                <div className='event-answer'>
                    <button disabled={selectedAnswer === 1} onClick={() => setSelectedAnswer(1)}>
                        {selectedAnswer === 1 && <img src={tickImage} alt="Selected" />}
                    </button>
                    <span className={`${selectedAnswer === 1 ? 'selected' : ''} ${selectedAnswer === 1 && answerResult}`} disabled={selectedAnswer === 1} onClick={() => setSelectedAnswer(1)}>{
                        window.event_voyages[0] == 0 ? t('hodl-answer1-1') :
                        window.event_voyages[0] == 1 ? t('hodl-answer2-1') : 
                        window.event_voyages[0] == 2 ? t('hodl-answer3-1') :
                        window.event_voyages[0] == 3 ? t('hodl-answer4-1') :
                        window.event_voyages[0] == 4 ? t('hodl-answer5-1') : null
                    }</span>
                    {animatePoint && selectedAnswer === 1 && <span className="point-animation">+1 point</span>}
                </div>
                <div className='event-answer'>
                    <button disabled={selectedAnswer === 2} onClick={() => setSelectedAnswer(2)}>
                        {selectedAnswer === 2 && <img src={tickImage} alt="Selected" />}
                    </button>
                    <span className={`${selectedAnswer === 2 ? 'selected' : ''} ${selectedAnswer === 2 && answerResult}`} disabled={selectedAnswer === 2} onClick={() => setSelectedAnswer(2)}>{
                        window.event_voyages[0] == 0 ? t('hodl-answer1-2') :
                        window.event_voyages[0] == 1 ? t('hodl-answer2-2') : 
                        window.event_voyages[0] == 2 ? t('hodl-answer3-2') :
                        window.event_voyages[0] == 3 ? t('hodl-answer4-2') :
                        window.event_voyages[0] == 4 ? t('hodl-answer5-2') : null
                    }</span>
                    {animatePoint && selectedAnswer === 2 && <span className="point-animation">+1 point</span>}
                </div>
                <div className='event-answer'>
                    <button disabled={selectedAnswer === 3} onClick={() => setSelectedAnswer(3)}>
                        {selectedAnswer === 3 && <img src={tickImage} alt="Selected" />}
                    </button>
                    <span className={`${selectedAnswer === 3 ? 'selected' : ''} ${selectedAnswer === 3 && answerResult}`} disabled={selectedAnswer === 3} onClick={() => setSelectedAnswer(3)}>{
                        window.event_voyages[0] == 0 ? t('hodl-answer1-3') :
                        window.event_voyages[0] == 1 ? t('hodl-answer2-3') : 
                        window.event_voyages[0] == 2 ? t('hodl-answer3-3') :
                        window.event_voyages[0] == 3 ? t('hodl-answer4-3') :
                        window.event_voyages[0] == 4 ? t('hodl-answer5-3') : null
                    }</span>
                    {animatePoint && selectedAnswer === 3 && <span className="point-animation">+1 point</span>}
                </div>
                <button className={`event-button ${loading || !selectedAnswer || animatePoint || answerResult === 'incorrect' ? 'loading' : ''}`} onClick={checkAnswer}>
                    {!loading && !animatePoint && (answerResult === 'corrent' || !answerResult) ?
                        t('next') :
                        <div className="loading-animation">
                            <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                    }
                </button>
            </>
        );
    };

    const handleChange = (newValue) => {
        intermediateValue.current = newValue;
        if (animatePoint !== newValue) {
            setAnimatePoint(newValue);
        }
    };

    const checkAnswer = () => {
        setLoading(true);

        const isCorrect = eventQuestModal === 'HODL, FUCK!' && selectedAnswer === trueAnswers.HODL[window.event_voyages[0]];

        setAnswerResult(isCorrect ? 'correct' : 'incorrect');

        if (isCorrect) {
            if (animatePoint !== true) {
                handleChange(true);
            }

            setTimeout(() => {
                setAnimatePoint(false);
                // Изменить очки, voyages
                changeEventData(window.tgID, eventQuestModal, window.event_voyages[0] + 1, 1)
                .then(() => {
                    handleSend2(sendData);
                    setLoading(false);
                    setEventQuestModal(false);
                })
                .catch(error => {
                    setLoading(false);
                });
            }, 1200);
        } else {
            setTimeout(() => {
                // Тут изменить только voyages
                changeEventData(window.tgID, eventQuestModal, window.event_voyages[0] + 1, 0)
                .then(() => {
                    handleSend2(sendData)
                    setLoading(false);
                    setEventQuestModal(false);
                })
                .catch(error => {
                    setLoading(false);
                });
            }, 1200);
        }
        setLoading(false);
    };

    return (
        <>
            {eventQuestModal && ReactDOM.createPortal(
                <div className="event-quest-overlay">
                    <div className="box">
                        {eventQuestModal == 'HODL, FUCK!' && <HODL />}
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default EventQuestModal;