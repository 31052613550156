import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './header-store.scss';
import { useTranslate } from '../translate/translate';
import { getUserInfo } from '../requests';
import { format, formatCapacity } from '../format-number';
import config_store from '../port/store/config_store.json';

import LeftArrowImage from '../port/arrow-left.png';
import RightArrowImage from '../port/arrow-right.png';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

import Component1Image from '../components/1.png';
import Component2Image from '../components/2.png';
import Component3Image from '../components/3.png';
import Component4Image from '../components/4.png';
import Component5Image from '../components/5.png';
import Component6Image from '../components/6.png';
import Component7Image from '../components/7.png';
import Component8Image from '../components/8.png';
import Component9Image from '../components/9.png';
import Component10Image from '../components/10.png';
import Component11Image from '../components/11.png';
import Component12Image from '../components/12.png';
import Component13Image from '../components/13.png';
import Component14Image from '../components/14.png';
import Component15Image from '../components/15.png';
import Component16Image from '../components/16.png';
import Component17Image from '../components/17.png';
import Component18Image from '../components/18.png';
import Component19Image from '../components/19.png';
import Component20Image from '../components/20.png';
import Component21Image from '../components/21.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const HeaderStore = ({ headerStore, setHeaderStore, storeType, setStoreType }) => {
    const t = useTranslate();
    const [rowCountResources, setRowCountResources] = useState(1);
    const [rowCountComponents, setRowCountComponents] = useState(1);
    const [availableResources, setAvailableResources] = useState({});
    const [availableComponents, setAvailableComponents] = useState({});

    const [capacityResources, setCapacityResources] = useState([]);
    const [capacityComponents, setCapacityComponents] = useState([]);

    const fetchData = async () => {
        if (!window.tgID) return;
    
        await getUserInfo(window.tgID);

        const storeLevel = config_store.store.find(store => store.lvl == window.lvl_store);
        if (storeLevel) {
            setCapacityResources(storeLevel.capacity[0].resources);
            setCapacityComponents(storeLevel.capacity[0].components);
        }
    
        const updateImagesMap = (items, imagesMap, setState) => {
            const newImagesMap = {};
            items.forEach((item, index) => {
                if (item > 0) {
                    const itemIndex = index + 1;
                    newImagesMap[itemIndex] = { image: imagesMap[itemIndex], value: item };
                }
            });
            setState(newImagesMap);
            return Object.keys(newImagesMap).length;
        };
    
        if (Array.isArray(window.resources)) {
            const resourceCount = updateImagesMap(window.resources, resourceImages, setAvailableResources);
            setRowCountResources(Math.ceil(resourceCount / 4));
        }
    
        if (Array.isArray(window.components)) {
            const componentCount = updateImagesMap(window.components, componentImages, setAvailableComponents);
            setRowCountComponents(Math.ceil(componentCount / 4));
        }
    };

    useEffect(() => {
        fetchData();
    }, [headerStore]);

    const changeStoreType = (direction) => {
        const storeTypes = ['Resources', 'Components', 'Technologies'];
        setStoreType((prevStoreType) => {
            const currentIndex = storeTypes.indexOf(prevStoreType);
            const newIndex = direction === 'left' ? 
                (currentIndex - 1 + storeTypes.length) % storeTypes.length : 
                (currentIndex + 1) % storeTypes.length;
            return storeTypes[newIndex];
        });
    };

    const renderCells = () => {
        const cells = [];
        let itemsArray;
        let rowCount;
    
        if (storeType === 'Resources') {
            itemsArray = Object.entries(availableResources).map(([key, value], index) => ({ key, ...value, originalIndex: index + 1 }));
            rowCount = rowCountResources;
        } else if (storeType === 'Components') {
            itemsArray = Object.entries(availableComponents).map(([key, value], index) => ({ key, ...value, originalIndex: index + 1 }));
            rowCount = rowCountComponents;
        } else if (storeType === 'Technologies') {
            return cells;
        }
    
        itemsArray.sort((a, b) => parseInt(a.key) - parseInt(b.key));
    
        for (let i = 0; i < rowCount * 4; i++) {
            const item = itemsArray[i];
            if (item) {
                cells.push(
                    <div key={i} className="header-store-cell">
                        <div className="header-store-cell-img">
                            <img src={item.image} alt={`${storeType.charAt(0).toUpperCase() + storeType.slice(1)} ${item.key}`} />
                        </div>
                        <div className={`header-store-cell-value ${(storeType === 'Resources' && item.value >= capacityResources[item.key-1]) || (storeType === 'Components' && item.value >= capacityComponents[item.key-1]) ? 'full' : ''}`}>
                        {storeType === 'Resources' ? `${format(item.value)}/${formatCapacity(capacityResources[item.key-1])}` :
                            `${formatCapacity(item.value)}/${formatCapacity(capacityComponents[item.key-1])}`}
                        </div>
                    </div>
                );
            } else {
                cells.push(<div key={i} className="header-store-cell"></div>);
            }
        }
        return cells;
    };

    const closeStore = () => {
        setStoreType('Resources');
        setRowCountResources(1);
        setRowCountComponents(1);
        setAvailableResources({});
        setHeaderStore(false);
        fetchData();
    };

    return (
        <>
            {headerStore ? (
                ReactDOM.createPortal(
                    <div className="header-store-overlay" onClick={() => closeStore()}>
                        <div className="header-store-box" onClick={(e) => e.stopPropagation()}>
                            <span className="header-store-title">{t(storeType)}</span>

                            <div className="header-store-page">
                                <button className='header-store-left' onClick={() => changeStoreType('left')} disabled={storeType === 'Resources'}>
                                    {storeType != 'Resources' ? <img src={LeftArrowImage} alt="Back" /> : null}
                                </button>

                                <div className="header-store-items-box">
                                    {renderCells()}
                                </div>

                                <button className='header-store-right' onClick={() => changeStoreType('right')} disabled={storeType === 'Technologies'}>
                                    {storeType != 'Technologies' ? <img src={RightArrowImage} alt="Next" /> : null}
                                </button>
                            </div>
                            
                        </div>
                    </div>,
                    document.body
                )
            ) : 
                null
            }
        </>
    );
};

export default HeaderStore;