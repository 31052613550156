import React, { useState, useEffect } from 'react';
import "./info.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../translate/translate';
import arrow from './arrow.png';

const Info = ({ info, setInfo }) => {
    const t = useTranslate();

    return (
        <>     
            {info ? (
                ReactDOM.createPortal(
                    <div className="airdrop-info-overlay" onClick={() => setInfo(false)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">Airdrop</div>
                            
                            <div className="content">
                                <span className="text1">{t('airdrop-info1')}</span>
                                <img src={arrow} alt="" />
                                <span className="text1">{t('airdrop-info2')}</span>
                                <img src={arrow} alt="" />
                                <span className="text1">{t('airdrop-info3')}</span>
                                <span className="text2"><i>{t('airdrop-info4')}</i></span>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default Info;