
import React, { useEffect, useState } from 'react';
import './port.scss';
import { useTranslate } from '../translate/translate';
import VoyageEnd from '../voyage/voyage_end';
import { getUserInfo } from '../requests'
import Alert from '../alerts';
import Rigs from './rigs/rigs.jsx';

import PortImage from './city.png';
import MarkerContImage from './cont.png';
import MarkerShipImage from './ship.png';
import MarkerRentImage from './rent-ship.png';
import MarkerResearchImage from './research.png';
import MarkerStoreImage from './store.png';
import MarkerWorkshopImage from './workshop.png';

const PortContent = ({ setActiveSection, setLVL, setXP }) => {
    const t = useTranslate();
    const [alert, setAlert] = useState(null);

    const fetchData = async () => {
        await getUserInfo(window.tgID);

    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}
            <VoyageEnd onVoyagePage={false} setLVL={setLVL} setXP={setXP}/>
            <div className="port-content">
                <div className="city">
                    <img className="port-image" src={PortImage} alt="Port" />
                    <div className="marker-container" id="port-marker-cont" onClick={() => setActiveSection('cont')}>
                        <img className="port-marker-image" src={MarkerContImage} alt="Containers" />
                        <div className="port-marker-name">{t("containers")}</div>
                    </div>
                    <div className="marker-container" id="port-marker-ship" onClick={() => setActiveSection('ships')}>
                        <img className="port-marker-image" src={MarkerShipImage} alt="Ships" />
                        <div className="port-marker-name">{t("ships")}</div>
                    </div>
                    <div className="marker-container" id="port-marker-rent" onClick={() => setActiveSection('rent')}>
                        <img className="port-marker-image" src={MarkerRentImage} alt="Rent ships" />
                        <div className="port-marker-name">{t("rents")}</div>
                    </div>
                    <div className="marker-info" id="port-marker-info" onClick={() => setActiveSection('research')}>
                        <img className="port-marker-image" src={MarkerResearchImage} alt="Research Centre" /> 
                        <div className="port-marker-name">{t("research")}</div>
                    </div>
                    <div className="marker-store" id="port-marker-store" onClick={() => setActiveSection('store')}>
                        <img className="port-marker-image" src={MarkerStoreImage} alt="Warehouse" /> 
                        <div className="port-marker-name">{t("store")}</div>
                    </div>
                    <div className="marker-workshop" id="port-marker-workshop" onClick={() => setActiveSection('workshop')}>
                        <img className="port-marker-image" src={MarkerWorkshopImage} alt="Workshop" /> 
                        <div className="port-marker-name">{t("workshop")}</div>
                    </div>
                </div>

                <Rigs alert={alert} setAlert={setAlert}/>
            </div>
        </>
    );
};

export default PortContent;