import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './voyages_list.scss';
import { useTranslate } from '../translate/translate';
import BackImage from '../port/back_white.png';
import { getShipById, getTimeServer } from '../requests'

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

const containerImages = {
    'blue-cont': ContBlueImage,
    'red-cont': ContRedImage,
    'green-cont': ContGreenImage,
    'bronze-cont': ContBronzeImage,
    'silver-cont': ContSilverImage,
    'gold-cont': ContGoldImage,
};

const ModalList = ({ modalListVoyages, setModalListVoyages, listSended, setActiveVoyage, setShowModalInfo }) => {
    const t = useTranslate();
    const [serverTimeIs, setServerTimeIs] = useState(false);
    const [serverTime, setServerTime] = useState(null);
    const serverTimeRef = useRef(null);

    const [ships, setShips] = useState([]);
    const [timers, setTimers] = useState({});

    // Функция для получения времени с сервера
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        setServerTime(serverTimeMs);
        serverTimeRef.current = serverTimeMs; // Сохраняем в ref для обновлений каждую секунду
        if (!serverTimeIs) {
            setServerTimeIs(true);
        }
    };

    useEffect(() => {
        let tickIntervalId;

        fetchServerTime();

        tickIntervalId = setInterval(() => {
            if (serverTimeRef.current) {
                serverTimeRef.current += 1000;
                setServerTime(serverTimeRef.current);
            }
        }, 1000);

        return () => {
            clearInterval(tickIntervalId);
        };
    }, []);

    const getShips = async () => {
        const ships_data = await Promise.all(
            listSended.map(async (voyage) => {
                const shipData = await getShipById(voyage.ship);
                return { ...voyage, ship_info: shipData };
            })
        );
        setShips(ships_data);

        // Инициализация таймеров
        const initialTimers = {};
        ships_data.forEach(voyage => {
            const endTime = new Date(voyage.timer).getTime();
            const timeLeft = endTime - serverTime;
            if (timeLeft > 0) {
                initialTimers[voyage.id] = timeLeft;
            }
        });
        setTimers(initialTimers);
    };

    useEffect(() => {
        getShips();
    }, [listSended, serverTimeIs]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers(prevTimers => {
                const newTimers = {};
                Object.keys(prevTimers).forEach(id => {
                    const timeLeft = prevTimers[id] - 1000;
                    if (timeLeft > 0) {
                        newTimers[id] = timeLeft;
                    }
                });
                return newTimers;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const formatTimeDifference = (timeLeft) => {
        if (timeLeft <= 0) {
            return '00:00:00';
        }

        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const close = () => {
        setModalListVoyages(false);
    };

    const showInfo = (voyage) => {
        setActiveVoyage(voyage);
        setShowModalInfo(true);
    };

    const renderContainers = (conts) => {
        const containerTypes = ['blue-cont', 'red-cont', 'green-cont', 'bronze-cont', 'silver-cont', 'gold-cont'];
        const nonZeroContainers = conts.filter(count => count > 0);
        const containerCount = nonZeroContainers.length;

        return (
            <div className={`modal-list-voyage-conts cont-count-${containerCount}`}>
                {conts.map((count, index) => {
                    if (count > 0) {
                        const containerType = containerTypes[index];
                        return (
                            <div key={index} className="modal-list-voyage-cont">
                                <img src={containerImages[containerType]} alt={containerType} />
                                <div className="modal-list-voyage-cont-amount">{count}</div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    return (
        <>
            {modalListVoyages && ReactDOM.createPortal(
                <div className="modal-list-overlay">
                    <div className="modal-list-box">
                        <div className="modal-list-header">
                            <button className="back-button" onClick={close}>
                                <img src={BackImage} alt="Back" />
                            </button>
                            <span className="modal-list-title">{t("sended-voyages")}</span>
                        </div>
                        <div className="modal-list-list">
                            {ships.map((voyage, index) => {
                                const timer = timers[voyage.id] ? formatTimeDifference(timers[voyage.id]) : '00:00:00';
                                return (
                                    <button key={index} className="modal-list-voyage-box" onClick={() => showInfo(voyage)}>
                                        <div className="modal-list-voyage-ship">{voyage.ship_info.name}</div>
                                        {renderContainers(voyage.conts)}
                                        <div className="modal-list-voyage-timer">
                                            {serverTimeIs ? timer : ''}
                                        </div>
                                    </button>
                                ) 
                            })}
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default ModalList;