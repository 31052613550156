
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './modal.scss';
import { getTimeServer } from '../../../requests'
import config from '../../country.json';


import { useTranslate } from '../../../translate/translate';

const DutchmanModal = ({ dutchmanModal, setDutchmanModal }) => {
    const t = useTranslate();
    const [content, setContent] = useState(null);

    const country = config.counties[13];

    const fetch = async () => {
        const time = await getTimeServer(); // Запрос на сервер

        if (country && (country['term-start'] > time)) {
            setContent('not-start');
        } else if (country && (country['term-end'] < time)) {
            setContent('end');
        } else {
            setContent('available');
        }
    };

    useEffect(() => {
        fetch();
    }, [country]);

    return (
        <>
            {dutchmanModal && ReactDOM.createPortal(
                <div className="dutchman-overlay" onClick={() => setDutchmanModal(false)}>
                    <div className="box" style={{ 'Height': '40vw' }} onClick={(e) => e.stopPropagation()}>
                        <div className="box-title">{t('dutchman-title')}</div>

                        {content ? (
                            content === 'not-start' ? (
                                <div className="event-message">{t('event-will-start')}</div>
                            ) : content === 'end' ? (
                                <div className="event-message">{t('event-end')}</div>
                            ) : content === 'available' ? (
                                <div className="event-message">Soon</div>
                            ) : null) 
                            : 
                            (
                                <div className="loading-content">
                                    <svg className="spinner" strokeWidth="65px" height="20%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                            )
                        }

                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default DutchmanModal;