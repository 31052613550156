import React, { useState, useEffect, useRef } from 'react';
import "./info.scss";
import ReactDOM from 'react-dom';
import { format, formatCapacity } from '../../format-number';
import { useTranslate } from '../../translate/translate';
import InfoStore from './info';

const InfoWorkshop = ({ info, setInfo }) => {
    const t = useTranslate();

    return (
        <>       
            {info ? (
                ReactDOM.createPortal(
                    <div className="info-workshop-overlay" onClick={() => setInfo(false)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{t("workshop")}</div>
                            <div className="text">
                                <span>{t("workshop2")}</span>
                                <span>{t("workshop3")}</span>
                                <span>{t("workshop4")}</span>
                                <span>{t("workshop5")}</span>
                                <span>____________________________________________</span>
                                <span></span>
                                <span><b>{t("workshop6")}</b></span>
                                <span className="text2">{t("workshop7")}</span>
                                <span className="text2">{t("workshop8")}</span>
                                <span className="text2">{t("workshop9")}</span>
                                <span className="text2">{t("workshop10")}</span>
                                <span>{t("workshop11")}</span>
                                <span className="text2"><i>{t("workshop12")}</i></span>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default InfoWorkshop;