
import React, { useState, useEffect } from 'react';
import { useTranslate } from '../translate/translate';
import './market.scss';

import BuyTab from './buy-tab';
import SellTab from './sell-tab';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

import Component1Image from '../components/1.png';
import Component2Image from '../components/2.png';
import Component3Image from '../components/3.png';
import Component4Image from '../components/4.png';
import Component5Image from '../components/5.png';
import Component6Image from '../components/6.png';
import Component7Image from '../components/7.png';
import Component8Image from '../components/8.png';
import Component9Image from '../components/9.png';
import Component10Image from '../components/10.png';
import Component11Image from '../components/11.png';
import Component12Image from '../components/12.png';
import Component13Image from '../components/13.png';
import Component14Image from '../components/14.png';
import Component15Image from '../components/15.png';
import Component16Image from '../components/16.png';
import Component17Image from '../components/17.png';
import Component18Image from '../components/18.png';
import Component19Image from '../components/19.png';
import Component20Image from '../components/20.png';
import Component21Image from '../components/21.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const resourceMinimum = {
    1: 100,
    2: 10,
    3: 5,
    4: 5,
    5: 5,
    6: 5,
    7: 5,
    8: 5,
    9: 5,
    10: 5,
    11: 5,
    12: 5,
    13: 5,
    14: 5
};

const Market = () => {
    const t = useTranslate();
    const [tab, setTab] = useState('buy');

    return (
        <>
        <div className='market'>
            <div className='switch-box'>
                <button 
                    className={`switch-button ${tab === 'buy' ? 'active' : ''}`} 
                    onClick={() => setTab('buy')}
                    disabled={tab === 'buy'}
                >
                    {t("market-buy")}
                </button>
                <button 
                    className={`switch-button ${tab === 'sell' ? 'active' : ''}`} 
                    onClick={() => setTab('sell')}
                    disabled={tab === 'sell'}
                >
                    {t("market-sell")}
                </button>
            </div>

            {tab === 'buy' ? <BuyTab resourceImages={resourceImages} componentImages={componentImages} resourceMinimum={resourceMinimum} /> :
                <SellTab resourceImages={resourceImages} componentImages={componentImages} resourceMinimum={resourceMinimum}/>}
        </div>
        </>
    );
};

export default Market;