import React, { useState, useEffect, useRef } from 'react';
import "./get.scss"
import ReactDOM from 'react-dom';

import { addDefaultConts, addDefaultShip } from '../requests';
import { useTranslate } from '../translate/translate';
import config from '../port/ships/ships_upgrade.json';
import capacityImage from '../port/ships/cont-icon.png'

import PioneerImage from '../port/ships/pioneer.png';
import ExplorerImage from '../port/ships/explorer.png';
import VoyagerImage from '../port/ships/voyager.png';
import OdysseyImage from '../port/ships/odyssey.png';
import TitanImage from '../port/ships/titan.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

const containerImages = {
    'Blue': ContBlueImage,
    'Red': ContRedImage,
    'Green': ContGreenImage,
    'Bronze': ContBronzeImage,
    'Silver': ContSilverImage,
    'Gold': ContGoldImage
};

const contsProbabilities = {
    'Blue': 31,
    'Red': 31,
    'Green': 31,
    'Bronze': 6,
    'Silver': 0.9,
    'Gold': 0.1
};

const contsIds = {
    'Blue': 0,
    'Red': 1,
    'Green': 2,
    'Bronze': 3,
    'Silver': 4,
    'Gold': 5
};

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const shipProbabilities = {
    'Pioneer': 86,
    'Explorer': 10,
    'Voyager': 3,
    'Odyssey': 0.9,
    'Titan': 0.1
};

const getShipCapacity = (shipType, level) => {
    const shipConfig = config.ships.find(ship => ship.type === shipType);
    if (shipConfig) {
        const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
        return levelConfig ? levelConfig.capacity : 'N/A';
    }
    return 'N/A';
};

const DefaultGetWindow = ({ type, setDefaultWindowShip, setDefaultWindowConts }) => {
    const t = useTranslate();
    const [showPyro, setShowPyro] = useState(false);
    const [buttonText, setButtonText] = useState(t("receive"));
    const [loading, setLoading] = useState(false);
    const [prize, setPrize] = useState(null);

    useEffect(() => {setButtonText(t("receive"))}, [t]);

    const getRandomPrize = (probabilities) => {
        const totalProbability = Object.values(probabilities).reduce((a, b) => a + b, 0);
        let random = Math.random() * totalProbability;

        for (let item in probabilities) {
            random -= probabilities[item];
            if (random <= 0) {
                return item;
            }
        }
    };

    const get = () => {
        setLoading(true);

        setTimeout(() => {
            const prizeType = type === 'ship' ? shipProbabilities : contsProbabilities;
            const randomPrize = getRandomPrize(prizeType);

            setPrize(randomPrize);
            setShowPyro(true);
            setButtonText(t("take"));

            if (type === 'ship' ) {
                window.default_ship = 1;
                addDefaultShip(window.tgID, randomPrize).then(() => setLoading(false));
            } else {
                window.default_conts = [1,1,1,1,1,1]
                addDefaultConts(window.tgID, contsIds[randomPrize], false).then(() => setLoading(false));
            }
        }, 1000);
    };

    const take = () => {
        if (type === 'ship') {
            setDefaultWindowShip(false);
        } else if (type === 'conts') {
            setDefaultWindowConts(false);
        }
    };

    return (
        <>
            {ReactDOM.createPortal(
                <div className="default-get-overlay">
                    <div className="default-get">
                        <span className="default-get-title">
                            {t("free")} {type === 'ship' ? t("ship") : t("cont")}!
                        </span>

                        {showPyro && (
                            <div className="pyro">
                                <div className="before"></div>
                                <div className="after"></div>
                            </div>
                        )}

                        <div className="container">
                            {prize ? (
                                <>
                                    {type === 'ship' ? (
                                        <>
                                            <div className="default-get-ship-name">{prize}</div>
                                            <div className="default-get-ship-capacity">
                                                {getShipCapacity(prize, 1)}
                                                <img src={capacityImage} alt="Capacity" />
                                            </div>
                                            <img src={shipImages[prize]} alt={prize} />
                                        </>
                                    ) : (
                                        <img src={containerImages[prize]} alt={prize} />
                                    )}
                                </>
                            ) : (
                                <span className="question-mark">?</span>
                            )}
                        </div>

                        <button
                            className="default-get-button"
                            onClick={buttonText === t("take") ? take : get}
                            disabled={loading}
                        >
                            {!loading ? 
                                buttonText
                                :
                                <div className="loading-animation">
                                    <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                            }
                        </button>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default DefaultGetWindow;
