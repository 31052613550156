
import React from 'react';
import './temp2_anounce.scss';

import Temp2Icon from './hmstr_coin.png';
import AnounceImage from './hmstr_anounce.png';

import { useTranslate } from '../../translate/translate';

const Temp2Anounce = ({  }) => {
    const t = useTranslate();

    return (
        <>
            <div className='temp2-box'>
                <span className='temp2-title'>{t("temp2-title")}</span>
                <img className='temp2-img' src={AnounceImage} alt="Hamster rescue" />
                <span className='temp2-info1'>{t("Temp1-title1")}</span>
                <span className='temp2-info2'>{t("temp2-info2")}</span>
                <span className='temp2-info3'>{t("temp2-info3")}</span>
                <span className='temp2-info4'>{t("temp2-info4")}</span>
                <span className='temp2-info5'>{t("temp2-info5")}</span>
                <div className='temp2-info6'>
                    <span>{t("temp2-info6")}</span>
                    <div className='temp2-info7'>
                        <span className='temp2-info8'>$HMSTR</span>
                        <img src={Temp2Icon} alt="HMSTR" />
                    </div>
                </div>
                <div className='temp2-soon'>{t("temp2-soon")}</div>
            </div>
        </>
    );
};

export default Temp2Anounce;