import React, { useState, useEffect, useRef } from 'react';
import "./upgrade.scss";
import ReactDOM from 'react-dom';
import { changeContLvl } from '../../requests';
import { format, formatCapacity } from '../../format-number';
import { useTranslate } from '../../translate/translate';
import UpgradeImage from './upgrade_yes.png';
import Alert from '../../alerts'
import InfoStore from './info';

import Resource1Image from '../../resources/1.png';
import Component1Image from '../../components/1.png';
import Component2Image from '../../components/2.png';
import Component3Image from '../../components/3.png';
import Component4Image from '../../components/4.png';
import Component5Image from '../../components/5.png';
import Component6Image from '../../components/6.png';
import Component7Image from '../../components/7.png';
import Component8Image from '../../components/8.png';
import Component9Image from '../../components/9.png';
import Component10Image from '../../components/10.png';
import Component11Image from '../../components/11.png';
import Component12Image from '../../components/12.png';
import Component13Image from '../../components/13.png';
import Component14Image from '../../components/14.png';
import Component15Image from '../../components/15.png';
import Component16Image from '../../components/16.png';
import Component17Image from '../../components/17.png';
import Component18Image from '../../components/18.png';
import Component19Image from '../../components/19.png';
import Component20Image from '../../components/20.png';
import Component21Image from '../../components/21.png';

const resourceImages = {
    1: Resource1Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const UpgradeCont = ({ modalUpgrade, setModalUpgrade, currentCont, setCurrentCont, config, fetchContainers }) => {
    const t = useTranslate();
    const [alert, setAlert] = useState(null);
    const [showPyro, setShowPyro] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);

    const nextLevelConfig = config.containers.find(c => c.id === currentCont.name)?.levels.find(l => l.level === (currentCont.level + 1));
    const userResources = window.resources.map((value, index) => value - window.resources_market[index]);
    const userComponents = window.components.map((value, index) => value - window.components_market[index]);

    const upgradeContainer = () => {
        setLoading(true);
        if (currentCont.level != 10) {
            const moneyCost = Array(14).fill(0);
    
            const resourceItem = currentCont.resources.find(item => item.resourceId !== undefined);
            if (resourceItem) {
                moneyCost[0] = resourceItem.amount;
            }
    
            const componentsCost = Array(21).fill(0);
            currentCont.resources.forEach(item => {
                if (item.componentId !== undefined) {
                    componentsCost[item.componentId - 1] = item.amount;
                }
            });
    
            const isMoney = moneyCost.every((amount, index) => amount <= 0 || amount <= userResources[index]);
            const isComponents = componentsCost.every((amount, index) => amount <= 0 || amount <= userComponents[index]);
    
            if (isMoney) {
                if (isComponents) {
                    changeContLvl(window.tgID, currentCont.id, moneyCost, componentsCost)
                        .then(() => {
                            fetchContainers();
    
                            const updatedCont = {
                                ...currentCont,
                                level: currentCont.level + 1,
                                bonus: nextLevelConfig.bonus_res,
                                resources: nextLevelConfig.requirements
                            };
                            setCurrentCont(updatedCont);
    
                            setShowPyro(true);
    
                            setTimeout(() => {
                                setShowPyro(false);
                            }, 1000);
                            setAlert('cont-lvlup');
                        })
                        .catch(error => {
                            console.error('Error upgrading container:', error);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setAlert('not-components-upgrade');
                    setLoading(false);
                }
            } else {
                setAlert('not-money-upgrade');
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <>      
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null} 
            {info ? <InfoStore info={info} setInfo={setInfo} currentCont={currentCont} config={config}/> : null}
            {modalUpgrade ? (
                ReactDOM.createPortal(
                    <div className="upgrade-cont-overlay" onClick={() => setModalUpgrade()}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <img className="image" src={currentCont.image} alt={currentCont.name} />
                            <button className="info-button" onClick={() => setInfo(true)}>i</button>
                            <div className="lvl-box-next">
                                <div className="lvl">{t("lvl")} {currentCont.level+1}</div>
                                <span className="res">{t('bonus')}: +{nextLevelConfig.bonus_res}%</span>
                            </div>
                            <img className="up-image" src={UpgradeImage} alt="Upgrade" />
                            <div className="lvl-box-current">
                                <div className="lvl">{t("lvl")} {currentCont.level}</div>
                                <span className="res">{t('bonus')}: +{currentCont.bonus}%</span>
                            </div>

                            {showPyro && (
                                <div className="pyro">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div>
                            )}

                            <div className="upgrade-box">
                                <div className="elements">
                                    {currentCont.resources.map((req, index) => (
                                        <div key={index} className="element">
                                            <div className="image">
                                                {req.resourceId ? 
                                                    <img src={resourceImages[req.resourceId]} alt={`Resource ${req.resourceId}`} /> :
                                                    <img src={componentImages[req.componentId]} alt={`Component ${req.componentId}`} />
                                                }
                                            </div>
                                            {req.resourceId ? <span>{format(req.amount)}</span> : <span>{formatCapacity(req.amount)}</span> }
                                        </div>
                                    ))}
                                </div>
                                <button className={`upgrade-button ${(loading) ? 'disabled' : ''}`} onClick={() => upgradeContainer()} >
                                    {loading ? (
                                        <div className="loading-animation">
                                            <svg className="spinner" strokeWidth="65px" height="20%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    ) : 
                                        <span>{t('upgrade')}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default UpgradeCont;