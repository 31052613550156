
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './airdrop.scss';
import { useTranslate } from '../translate/translate';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { changeWallet, getUserInfo } from '../requests';
import { format, formatMarket } from '../format-number';
import Alert from '../alerts';
import SelectModal from './select-modal';
import Info from './info';

import CoinImage from '../navigation/airdrop.png';
import WalletImage from '../profile/wallet.png'
import arrowImage from '../navigation/arrow-down.png'

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

import Component1Image from '../components/1.png';
import Component2Image from '../components/2.png';
import Component3Image from '../components/3.png';
import Component4Image from '../components/4.png';
import Component5Image from '../components/5.png';
import Component6Image from '../components/6.png';
import Component7Image from '../components/7.png';
import Component8Image from '../components/8.png';
import Component9Image from '../components/9.png';
import Component10Image from '../components/10.png';
import Component11Image from '../components/11.png';
import Component12Image from '../components/12.png';
import Component13Image from '../components/13.png';
import Component14Image from '../components/14.png';
import Component15Image from '../components/15.png';
import Component16Image from '../components/16.png';
import Component17Image from '../components/17.png';
import Component18Image from '../components/18.png';
import Component19Image from '../components/19.png';
import Component20Image from '../components/20.png';
import Component21Image from '../components/21.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const Airdrop = () => {
    const t = useTranslate();
    const [tonConnectUI] = useTonConnectUI();
    const wallet = useTonAddress(false);
    const [isConnected, setIsConnected] = useState(false);
    const [type, setType] = useState('All');
    const [isSwap, setIsSwap] = useState(false);
    const [resources, setResources] = useState(null);
    const [components, setComponents] = useState(null);
    const [alert, setAlert] = useState();
    const [selectResource, setSelectResource] = useState(null);
    const [selectComponent, setSelectComponent] = useState(null);
    const [coins, setCoins] = useState(0);
    const [info, setInfo] = useState(false);

    const fetchData = async () => {
        await getUserInfo(window.tgID);
    
        setResources(window.resources.map((value, index) => value - window.resources_market[index]));
        setComponents(window.components.map((value, index) => value - window.components_market[index]));
        setCoins(window.coins);
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    const noResources = resources && resources.every(value => value <= 0);
    const noComponents = components && components.every(value => value <= 0);

    useEffect(() => {
        const checkWalletConnection = async () => {
            const isConnected = tonConnectUI.connected;
            if (isConnected) {
                setIsConnected(true); // Кошелек подключен
                await changeWallet(window.tgID, wallet); // Присваивается window.wallet
            } else {
                setIsConnected(false); // Кошелек не подключен
            }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);

    const handleSwapClick = () => {
        setIsSwap(!isSwap);
    };

    const handleOptionClick = (option) => {
        setType(option);
        setIsSwap(false);
    };
    
    return (
        <>
        {alert && <Alert alert={alert} setAlert={setAlert} />} 
        {(selectResource || selectComponent) && <SelectModal resourceImages={resourceImages} componentImages={componentImages} selectResource={selectResource} setSelectResource={setSelectResource} selectComponent={selectComponent} setSelectComponent={setSelectComponent} setResources={setResources} setComponents={setComponents} setCoins={setCoins} setAlert={setAlert}/>}
        {info && <Info info={info} setInfo={setInfo} />} 

        <div className="airdrop-page">
            <div className="airdrop-header">
                <span>Airdrop</span>
                <button className="airdrop-info" onClick={() => setInfo(true)}>i</button>
            </div>

            <div className="balance-box">
                <span className="balance">
                    <span>{t('balance')} </span>
                    <span className="coin">$SEACOIN</span>
                </span>

                <div className="balance-box-amount">
                    <img src={CoinImage} alt="$SEACOIN" />
                    <span>{coins.toFixed(2)}</span>
                </div>
            </div>

            {isConnected ? 
                <div className="items-box">

                    <div className="items-header">
                        <span className="items-title">{t('exchange')}</span>

                        <div className={`swap-button ${isSwap ? 'swap' : ''}`} onClick={handleSwapClick}>
                            <div className="button-content">
                                <span>{type === 'All' ? t('all') : type === 'Resources' ? 'Resources' : 'Components'}</span>
                                <img src={arrowImage} alt="Swap" />
                            </div>

                            {isSwap && (
                                <div className="options-box">
                                    <button onClick={() => handleOptionClick('All')}>{t('all')}</button>
                                    <button onClick={() => handleOptionClick('Resources')}>{t('Resources')}</button>
                                    <button onClick={() => handleOptionClick('Components')}>{t('Components')}</button>
                                </div>
                            )}
                        </div>

                    </div>

                    <div className='store'>
                        {type === 'All' ? (
                            <>
                            {(resources && components) ? (
                                [...resources, ...components].map((value, index) => {
                                    if (value > 0) {
                                        const isResource = index < resources.length;
                                        const imageKey = isResource ? index + 1 : index - resources.length + 1;
                                        const image = isResource ? resourceImages[imageKey] : componentImages[imageKey];
                                        const formatter = isResource ? format : formatMarket;
                                        const altText = isResource ? 'Resource' : 'Component';

                                        return (
                                            <button key={index} className={`element-button`}  onClick={() => (isResource ? setSelectResource(imageKey) : setSelectComponent(imageKey))}>
                                                <img src={image} alt={altText} />
                                                <span>{formatter(value)}</span>
                                            </button>
                                        );
                                    }
                                    return null;
                                })
                            ) : null}
                            {noComponents && noResources && <span className='no-items'>{t('no-elements')}</span>}
                            </>
                        ) : type === 'Resources' ? (
                            <>
                            {resources ? (
                                resources.map((value, index) => {
                                    if (resources[index] > 0) {
                                        return (
                                            <button key={index} className={`element-button`} onClick={() => setSelectResource(index+1)}>
                                                <img src={resourceImages[index+1]} alt='Resource' />
                                                <span>{format(value)}</span>
                                            </button>
                                        );
                                    }
                                    return null;
                                })
                            ) : null}
                            {noResources && <span className='no-items'>{t('no-resources')}</span>}
                            </>
                        ) : type === 'Components' && (
                            <>
                            {components ? (
                                components.map((value, index) => {
                                    if (components[index] > 0) {
                                        return (
                                            <button key={index} className={`element-button`} onClick={() => setSelectComponent(index+1)}>
                                                <img src={componentImages[index+1]} alt='Component' />
                                                <span>{formatMarket(value)}</span>
                                            </button>
                                        );
                                    }
                                    return null;
                                })
                            ) : null}
                            {noComponents && <span className='no-items'>{t('no-components')}</span>}
                            </>
                        )}
                    </div>

                </div>
                : 
                <button className="airdrop-wallet" onClick={ async () => await tonConnectUI.openModal()}>
                    <img src={WalletImage} alt="Wallet" />
                    <span>{t('task11')}</span>
                </button>
            }
        </div> 
        </>
    );
};

export default Airdrop;