import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import {TonConnectUIProvider} from "@tonconnect/ui-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TonConnectUIProvider manifestUrl="https://cont-rush.online/tonconnect-manifest.json">
    <App />
  </TonConnectUIProvider>
);

reportWebVitals();
