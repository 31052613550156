import React, { useState, useEffect, useRef } from 'react';
import "./rent_out.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../../translate/translate';
import Alert from '../../alerts'

import capacityImage from './cont-icon.png'
import TonImage from '../../market/ton.png';

const RentModal = ({ modalRent, setModalRent, selectedShip, shipImages, getShipCapacity, rentOutConfirm }) => {
    const t = useTranslate();
    const [localPrice, setLocalPrice] = useState('');
    const [localPlaceholder, setLocalPlaceholder] = useState(t('input-price'));
    const [inputError, setInputError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);

    const handlePriceChange = (value) => {
        if (/^0\d/.test(value) || /^\./.test(value)) return;
        if (/^\d{0,3}(\.\d{0,5})?$/.test(value) || value === '') {
            setLocalPrice(value);
            setInputError(false);
        }
    };

    const handleConfirm = () => {
        setLoading(true);

        if (localPrice.trim() === '') {
            setInputError(true);
            setLoading(false);
            return;
        }
        const priceValue = parseFloat(localPrice);
        if (isNaN(priceValue) || priceValue === 0) {
            setInputError(true);
            setLoading(false);
            return;
        }

        rentOutConfirm(selectedShip.id, localPrice);
        setLocalPrice('');
        setInputError(false);
        setLoading(false);
        setModalRent(false);
    };

    const handleClose = () => {
        setLocalPrice('');
        setInputError(false);
        setLoading(false);
        setModalRent(false);
    };
    
    return (
        <>      
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null} 

            {modalRent ? (
                ReactDOM.createPortal(
                    <div className="rent-out-overlay" onClick={() => handleClose()}>
                        <div className="modal-rent-out" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-ship-img-box">
                                <img src={shipImages[selectedShip.type]} alt={selectedShip.type} className="ship-image" />
                                <div className="modal-ship-name">{selectedShip.name}</div>
                                <div className="modal-ship-lvl">{t('lvl')} {selectedShip.lvl}</div>
                                <div className="modal-ship-capacity">
                                    {getShipCapacity(selectedShip.type, selectedShip.lvl)}
                                    <img src={capacityImage} alt='capacity'/>
                                </div>
                            </div>
                            <span className="modal-rent-text">{t('modal-rent-text')}</span>
                            <span className="modal-rent-info">{t('modal-rent-info')}</span>
                            <input
                                className={`modal-rent-input ${inputError ? 'error' : ''}`}
                                id="modal-rent-input"
                                value={localPrice}
                                placeholder={localPlaceholder}
                                onChange={(e) => handlePriceChange(e.target.value)}
                                onFocus={() => setLocalPlaceholder('')}
                                onBlur={() => setLocalPlaceholder(t('input-price'))}
                            />
                            <img className="modal-rent-ton-img" src={TonImage} alt="TON" />

                            <button className={`modal-button-yes ${(loading) ? 'disabled' : ''}`} onClick={() => handleConfirm()}>
                                {loading ? (
                                    <div className="loading-loading">
                                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                ) : (
                                    t('ok')
                                )}
                            </button>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default RentModal;