import React, { useState, useEffect } from 'react';
import "./buy-modal.scss";
import ReactDOM from 'react-dom';
import { buyLot, buyLotCheck, setViewsLot } from '../requests';
import { useTranslate } from '../translate/translate';
import { beginCell, toNano } from '@ton/core';
import TonWeb from "tonweb";

const ModalBuy = ({ buyModal, setBuyModal, resourceImages, componentImages, getViewsLot, setAlert, 
resourcesName, componentsName, setBuyingLot, tonConnectUI, fetchItems, setItems }) => {

    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    function generateID() {
        const randomNumber = Math.floor(100000000000 + Math.random() * 900000000000);
        return randomNumber;
    }

    function getBounceableAddress(rawAddress) {
        const address = new TonWeb.Address(rawAddress);
        return address.toString(true, true, true);
    }

    const buyClick = async () => {
        const viewsStatus = await getViewsLot(buyModal.id);
        if (viewsStatus) {
            setBuyModal(null);
            setAlert('buy-error');
        } else {
            setLoading(true);
            const transactionID = generateID();
    
            const body = beginCell()
                .storeUint(0, 32)
                .storeStringTail(`Container Rush: Market ${buyModal.value} ${buyModal.type === 'resources' ? resourcesName[buyModal.number] : componentsName[buyModal.number]} (${transactionID})`)
                .endCell();
    
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 30, // 40 sec
                messages: [
                    {
                        address: buyModal.wallet_seller,
                        amount: buyModal.price * 10**9, //
                        payload: body.toBoc().toString("base64")
                    }
                ]
            };
    
            setBuyingLot(buyModal.id);

            if (transactionID) {
                await buyLot(buyModal.id, window.tgID, transactionID);
                try {
                    await tonConnectUI.sendTransaction(transaction);
                    setBuyingLot([]);
                    setItems(prevLots => prevLots.filter(lot => lot.id !== buyModal.id));
                    setLoading(false);
                    setBuyModal(null);
                    setBuyingLot(null);
                    setAlert('market-buy');
                } catch { // Транзакция отменена
                    await setViewsLot(buyModal.id);
                    setLoading(false);
                    setBuyingLot([]);
                    setBuyModal(null);
                    setBuyingLot(null);
                    setLoading1(false);
                    setAlert('market-buy-close');
                }
            }
        }
    };

    const closeBuy = () => {
        setBuyingLot([]);
        setBuyModal(null)
    };

    return (
        <>      
            {buyModal ? (
                ReactDOM.createPortal(
                    <div className="buy-modal-overlay">
                        <div className="box">
                            <div className="title">{t('market-buy')}</div>
                            <div className="modal-message">
                                <span className="modal-message-answer">{t('modal-message-answer')}</span>
                                <div className="modal-message-value-price">
                                    <span className="modal-message-value">{buyModal.value}</span>
                                    <img src={buyModal.type === 'resources' ? resourceImages[((buyModal.number - 1 + 14) % 14) + 1] : componentImages[((buyModal.number - 1 + 21) % 21) + 1]} alt={buyModal.type} />
                                    <span>{t('for')} {buyModal.price} TON?</span>
                                </div>
                                <span className="modal-message-alert"><i>{t('market-alert-buy')}</i></span>
                            </div>

                            <div className="modal-buttons">
                                <button className={`modal-button-yes ${loading ? 'loading' : ''}`} disabled={loading} onClick={() => buyClick()}>
                                    {!loading ? t('yes') : (
                                        <div className="loading-animation-buy">
                                            <svg className="spinner" strokeWidth="65px" height="70%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                            </svg>
                                        </div>
                                    )}
                                </button>
                                <button className={`modal-button-no ${loading ? 'loading-no' : ''}`} disabled={loading} onClick={() => closeBuy()}>
                                    {t('no')}
                                </button>
                            </div>

                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default ModalBuy;