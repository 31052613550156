import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './event_info.scss';
import { useTranslate } from '../../translate/translate';
import { changeEventView } from '../../requests'

import HODLImageQuest from './hodl_quest.jpg'
import BudgetImage from './budget.png'
import GgImage from '../../profile/gg.png'

const HODL = () => {
    const t = useTranslate();
    return (
        <>
            <img className='event-image' src={HODLImageQuest} alt="HODL, FUCK!" />
            <div className='budget'>
                <div className='image-box'>
                    <img src={BudgetImage} alt="Budget" />
                </div>
                <div className='amount-box'>
                    10 NFT "HODL, FUCK!"
                </div>
            </div>
            <button className='partner-collection' onClick={() => window.Telegram.WebApp.openLink('https://getgems.io/collection/EQBLHKsOWa9kFL9CkCBCDjNW1sM7uhjk77wbEPkp7hr8vT3h')}>
                <img src={GgImage} alt="HODL, FUCK! collection" />
            </button>
            <span className='text'>{t('hodl1')}</span>
            <span className='text'>{t('hodl2')} <b>Container Rush</b>, {t('hodl3')}
            </span>
            <span className='text'>{t('hodl4')}</span>
            <span className='text center'><i>{t('hodl5')} NFT "HODL, FUCK!"</i></span>
        </>
    );
};

const EventInfo = ({ eventInfo, setEventInfo, setNewEvent }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);

    const close = () => {
        setLoading(true);
        if (eventInfo.name === "HODL, FUCK!" && (window.event_view == null || (window.event_view && (Object.keys(window.event_view).length === 0 || !window.event_view[0])))) {
          changeEventView(window.tgID, eventInfo.name)
            .then(() => {
              setLoading(false);
              setNewEvent(false);
              setEventInfo(false);
            })
            .catch((error) => {
              setLoading(false);
            });
        } else {
          setLoading(false);
          setNewEvent(false);
          setEventInfo(false);
        }
      };

    return (
        <>
            {eventInfo && ReactDOM.createPortal(
                <div className="event-info-overlay">
                    <div className="box">
                        <HODL />
                        <button className={`box-button ${loading ? 'disabled' : ''}`} disabled={loading} onClick={close}>
                            {!loading ? (
                                t('go-to-voyage')
                            ) : (
                                <div className="loading-animation">
                                    <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                            )}
                        </button>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default EventInfo;