import React, { useState, useEffect, useRef } from 'react';
import "./info.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../../translate/translate';

import LeftArrowImage from '../arrow-left.png';
import RightArrowImage from '../arrow-right.png';
import capacityImage from './cont-icon.png'
import speedImage from './speed.png'

const InfoShips = ({ info, setInfo, currentShip, config, shipImages }) => {
    const t = useTranslate();
    const [lvlPage, setLvlPage] = useState(currentShip.lvl);
    const [capacity, setCapacity] = useState(currentShip.capacity);
    const [speed, setSpeed] = useState(currentShip.speed);

    const ship = config.ships.find(ship => ship.type === currentShip.type);

    useEffect(() => {
        const currentLevel = ship.levels.find(level => level.level === lvlPage);

        if (currentLevel) {
            setCapacity(currentLevel.capacity);
            setSpeed(currentLevel.speed);
        }
    }, [lvlPage, currentShip.type, config]);

    const setLevel = (side) => {
        if (side === 'prev') {
            setLvlPage(prevLvlPage => prevLvlPage - 1);
        } else {
            setLvlPage(prevLvlPage => prevLvlPage + 1);
        }
    }

    return (
        <>       
            {info ? (
                ReactDOM.createPortal(
                    <div className="info-ships-overlay" onClick={() => setInfo(false)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{currentShip.type}</div>

                            <div className='nav'>
                                <button className='info-lvl-left' onClick={() => setLevel('prev')} disabled={lvlPage === 1}>
                                    {lvlPage != 1 ? <img src={LeftArrowImage} alt="Back" /> : null}
                                </button>

                                <div className='ship-box'>
                                    <img src={shipImages[currentShip.type]} alt={currentShip.type} />
                                    <div className='lvl'>{t('lvl')} {lvlPage}</div>

                                    <div className='stat'>
                                        <div className='capacity'>
                                            <div className='image'><img src={capacityImage} alt="Capacity" /></div>
                                            <span>{capacity}</span>
                                        </div>
                                        <div className='speed'>
                                            <div className='image'><img src={speedImage} alt="Speed" /></div>
                                            <span>+{speed}%</span>
                                        </div>
                                    </div>
                                </div>

                                <button className='info-lvl-right' onClick={() => setLevel('next')} disabled={lvlPage === 10}>
                                    {lvlPage != 10 ? <img src={RightArrowImage} alt="Next" /> : null}
                                </button>
                            </div>

                            <div className="text">
                                <span className="text2">___________________________________________________</span>
                                <span className="text2"></span>
                                <span className="text2">{t('ship-info1')}</span>
                                <span className="text2">{t('ship-info2')}</span>
                                <span className="text2"><i>{t('ship-info3')}</i></span>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default InfoShips;