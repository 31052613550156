import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './event_subscribe.scss';
import { useTranslate } from '../../translate/translate';
import { checkSubscribe } from '../../requests'

const EventSubscribe = ({ eventSubscribe, setEventSubscribe, setIsSending }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);

    const check = () => {
        setLoading(true);
        const chatId = '@hodl_fuck';
            checkSubscribe(window.tgID, chatId)
            .then((checkSubs) => {
                if (checkSubs) {
                    setLoading(false);
                    setIsSending(false);
                    setEventSubscribe(false);
                } else {
                    setLoading(false);
                    setIsSending(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                setIsSending(false);
            });
    };

    return (
        <>
            {eventSubscribe && ReactDOM.createPortal(
                <div className="event-subscribe-overlay" onClick={() => setEventSubscribe(false)}>
                    <div className="box" onClick={(e) => e.stopPropagation()}>
                        <span className="text">{t('check1')} HODL, FUCK!</span>
                        
                        <button className="subscribe" onClick={() => window.Telegram.WebApp.openTelegramLink('https://t.me/hodl_fuck')}>
                            {t('subscribe')}
                        </button>

                        <button className={`check ${loading ? 'loading' : ''}`} disabled={loading} onClick={check}>
                            {!loading ? (
                                t('check')
                            ) : (
                                <div className="loading-animation">
                                    <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                    </svg>
                                </div>
                            )}
                        </button>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default EventSubscribe;