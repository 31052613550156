import React, { useState, useEffect, useRef } from 'react';
import "./full_store.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../translate/translate';

const FullStoreModal = ({ fullStore, setFullStore, handleSend2, sendData, sendCancel, countryName, setEventQuestModal }) => {
    const t = useTranslate();

    const cancel = () => {
        sendCancel();
        setFullStore(false);
    };

    const voyage = () => {
        if (countryName == 'HODL, FUCK!') {
            setEventQuestModal(countryName);
            setFullStore(false);
        } else {
            handleSend2(sendData);
            setFullStore(false);
        }
    };

    return (
        <>       
            {fullStore ? (
                ReactDOM.createPortal(
                    <div className="voyage-full-overlay">
                        <div className="box">
                            <div className="title">{t('voyage-full1')}</div>
                            <div className="text">
                                <span>{t('voyage-full2')}</span>
                            </div>
                            <div className="buttons">
                                <button className="button1" onClick={() => cancel()}>
                                    {t('voyage-full3')}
                                </button>

                                <button className="button2" onClick={() => voyage()}>
                                    {t('voyage-full4')}
                                </button>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default FullStoreModal;