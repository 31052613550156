import React, { useState, useEffect, useRef } from 'react';
import "./info.scss";
import ReactDOM from 'react-dom';
import { formatCapacity } from '../../format-number';
import { useTranslate } from '../../translate/translate';

import LeftArrowImage from '../arrow-left.png';
import RightArrowImage from '../arrow-right.png';

const InfoStore = ({ infoStore, setInfoStore, resourceImages, componentImages, config_store }) => {
    const t = useTranslate();
    const [lvlPage, setLvlPage] = useState(window.lvl_store);
    const elementsRef = useRef(null);

    const current = (type) => {
        const currentLvlConfig = config_store.store.find(config => config.lvl === lvlPage);
        if (!currentLvlConfig) { return null }
        if (type === 'resources') {
            return currentLvlConfig.capacity[0].resources;
        } else {
            return currentLvlConfig.capacity[0].components;
        }    
    }

    useEffect(() => {
        if (elementsRef.current) {
            elementsRef.current.scrollTo(0, 0);
        }
    }, [lvlPage]);

    return (
        <>
            {infoStore ? (
                ReactDOM.createPortal(
                    <div className="info-store-overlay" onClick={() => setInfoStore(false)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">
                                <span>{t('store')}</span>
                                <div className="title-lvl">{lvlPage} {t('lvl')}</div>
                            </div>
                            <div className="nav">
                                <button className='info-lvl-left' onClick={() => setLvlPage(prevLvlPage => prevLvlPage - 1)} disabled={lvlPage === 1}>
                                    {lvlPage != 1 ? <img src={LeftArrowImage} alt="Back" /> : null}
                                </button>

                                <div className="lvl-box">
                                    <div className="capacity-title">
                                        <span className="capacity">{t('capacity')}</span>
                                    </div>
                                    <div className="elements" ref={elementsRef}>
                                        {current('resources').map((capacity, index) => (
                                            <div key={index} className="element">
                                                <div className="image">
                                                    <img src={resourceImages[index + 1]} alt={`Resource ${index + 1}`} />
                                                </div>
                                                <span className="capacity">{formatCapacity(capacity)}</span>
                                            </div>
                                        ))}
                                        {current('components').map((capacity, index) => (
                                            <div key={index} className="element">
                                                <div className="image">
                                                    <img src={componentImages[index + 1]} alt={`Component ${index + 1}`} />
                                                </div>
                                                <span className="capacity">{formatCapacity(capacity)}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <button className='info-lvl-right' onClick={() => setLvlPage(prevLvlPage => prevLvlPage + 1)} disabled={lvlPage === 10}>
                                    {lvlPage != 10 ? <img src={RightArrowImage} alt="Next" /> : null}
                                </button>
                            </div>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default InfoStore;