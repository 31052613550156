import React, { useState, useEffect } from 'react';
import "./levelup.scss";
import ReactDOM from 'react-dom';
import { useTranslate } from '../translate/translate';

import AmericaImage from './country/America.jpeg';
import AfricaImage from './country/Africa.jpeg';
import BrazilImage from './country/Brazil.jpeg';
import SaudiImage from './country/Saudi.jpeg';
import ChinaImage from './country/China.jpeg';
import RussiaImage from './country/Russia.jpeg';
import MadagascarImage from './country/Madagascar.jpeg';
import IndiaImage from './country/India.jpeg';
import GreenImage from './country/Greenland.jpeg';
import AustraliaImage from './country/Australia.jpeg';
import AntarcticaImage from './country/Antarctica.jpeg';

const countryImages = {
    "North America": AmericaImage,
    "Africa": AfricaImage,
    "Brazil": BrazilImage,
    "Saudi Arabia": SaudiImage,
    "China": ChinaImage,
    "Russia": RussiaImage,
    "Madagascar": MadagascarImage,
    "India": IndiaImage,
    "Greenland": GreenImage,
    "Australia": AustraliaImage,
    "Antarctica": AntarcticaImage
};

const LevelupModal = ({ levelupModal, setLevelupModal }) => {
    const t = useTranslate();
    const [showPyro, setShowPyro] = useState(false);

    const voyages = [
        { level: 4, country: "North America" },
        { level: 7, country: "Africa" },
        { level: 10, country: "Brazil" },
        { level: 20, country: "Saudi Arabia" },
        { level: 30, country: "China" },
        { level: 40, country: "Russia" },
        { level: 50, country: "Madagascar" },
        { level: 60, country: "India" },
        { level: 70, country: "Greenland" },
        { level: 80, country: "Australia" },
        { level: 90, country: "Antarctica" }
    ];

    const voyage = voyages.find(v => v.level === levelupModal);

    useEffect(() => {
        setShowPyro(true);
        const timer = setTimeout(() => {
            setShowPyro(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {levelupModal ? (
                ReactDOM.createPortal(
                    <div className="levelup-overlay" onClick={() => setLevelupModal(null)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{t('levelup1')}</div>
                            {voyage ? (
                                <> 
                                <span className="text1">{t('levelup2')} {levelupModal}!</span>
                                <span className="text12">{t('levelup3')}</span>
                                <div className="voyage">
                                    <img src={countryImages[voyage.country]} alt={voyage.country} />
                                    <div className="name">{t(voyage.country)}</div>
                                </div>
                                </>
                            ) : (
                                <>
                                <span className="text2">{t('levelup4')}</span>
                            
                                <div className="lvl2">
                                    {levelupModal}
                                </div>
                                </>
                            )}
                            
                            {showPyro && (
                                <div className="pyro">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div>
                            )}
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default LevelupModal;