
import React, { useState, useEffect } from 'react';
import './research.scss';
import { useTranslate } from '../../translate/translate';

import BackImage from '../back.png';

import {  } from '../../requests'

import createImage from './create.jpg';

import Resource1Image from '../../resources/1.png';
import Resource3Image from '../../resources/3.png';
import Resource4Image from '../../resources/4.png';
import Resource5Image from '../../resources/5.png';
import Resource6Image from '../../resources/6.png';
import Resource7Image from '../../resources/7.png';
import Resource8Image from '../../resources/8.png';
import Resource9Image from '../../resources/9.png';
import Resource10Image from '../../resources/10.png';
import Resource11Image from '../../resources/11.png';
import Resource12Image from '../../resources/12.png';
import Resource13Image from '../../resources/11.png';
import Resource14Image from '../../resources/12.png';

import Component1Image from '../../components/1.png';
import Component2Image from '../../components/2.png';
import Component3Image from '../../components/3.png';
import Component4Image from '../../components/4.png';
import Component5Image from '../../components/5.png';
import Component6Image from '../../components/6.png';
import Component7Image from '../../components/7.png';
import Component8Image from '../../components/8.png';
import Component9Image from '../../components/9.png';
import Component10Image from '../../components/10.png';
import Component11Image from '../../components/11.png';
import Component12Image from '../../components/12.png';
import Component13Image from '../../components/13.png';
import Component14Image from '../../components/14.png';
import Component15Image from '../../components/15.png';
import Component16Image from '../../components/16.png';
import Component17Image from '../../components/17.png';
import Component18Image from '../../components/18.png';
import Component19Image from '../../components/19.png';
import Component20Image from '../../components/20.png';
import Component21Image from '../../components/21.png';

const resourceImages = {
    1: Resource1Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const recipe = {
    1: {component: 1, amount: 15},
    2: {component: 2, amount: 10},
    3: {component: 6, amount: 7},
    4: {component: 9, amount: 2},
    5: {component: 12, amount: 1},
};

const Research = ({ setActiveSection }) => {
    const t = useTranslate();

    return (
        <>
            <div className="research-header">
                <button className="research-back-button" onClick={() => setActiveSection('port')}>
                    <img src={BackImage} alt="Back" />
                </button>
                <div className="research-title">
                    <span>{t("research")} ({t('soon')})</span>
                </div>
            </div>

            <div className="research-switch">
                <button className="research-switch-button">{t('creation')}</button>
                <button className="research-switch-button">{t('merger')}</button>
                <button className="research-switch-button">{t('combination')}</button>
            </div>

            <div className="research-content">
                <div className="create-box">
                    <img className="create-img" src={createImage} alt="Research centre" />
                    <button className="create-info">i</button> {/* onClick={() => setInfo(true)} */}
                    <div className="research-portal">
                        <div className="portal-core"></div>
                        <div className="portal-glow"></div>
                        <div className="portal-light"></div>
                    </div>

                    <div className="recipe-box">
                        <div className="center">?</div>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <button key={index} className='component'>
                                <img src={componentImages[recipe[index+1].component]} alt={`Component ${index}`} />
                                <span>5</span>
                            </button>
                        ))}
                    </div>
                </div>

                <button className="create-button">
                    {t('create')}
                    <span>100</span>
                    <img src={resourceImages[1]} alt="" />
                </button>
            </div>

            <div className='soon'></div>

        </>
    );
};

export default Research;