import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import './voyage_end.scss';
import { useTranslate } from '../translate/translate';
import { getMyVoyages, getShipById, endVoyage, getUserInfo, changeEventData, getTimeServer } from '../requests';

import config_ship from '../port/ships/ships_upgrade.json';
import config_store from '../port/store/config_store.json';
import { format } from '../format-number';
import DeductalModal from './deducted';
import LevelupModal from './levelup';

import XpImage from '../port/xp.png';
import capacityImage from '../port/ships/cont-icon.png'

import PioneerImage from '../port/ships/pioneer.png';
import ExplorerImage from '../port/ships/explorer.png';
import VoyagerImage from '../port/ships/voyager.png';
import OdysseyImage from '../port/ships/odyssey.png';
import TitanImage from '../port/ships/titan.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';
import Temp1Resource from './temp/temp1_resource.png'

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image,
    15: Temp1Resource
};

const containerImages = {
    'blue-cont': ContBlueImage,
    'red-cont': ContRedImage,
    'green-cont': ContGreenImage,
    'bronze-cont': ContBronzeImage,
    'silver-cont': ContSilverImage,
    'gold-cont': ContGoldImage,
};

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const getShipCapacity = (shipType, level) => {
    const shipConfig = config_ship.ships.find(ship => ship.type === shipType);
    if (shipConfig) {
        const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
        return levelConfig ? levelConfig.capacity : 'N/A';
    }
    return 'N/A';
};

const VoyageEnd = ({ onVoyagePage, setRefreshData, setLVL, setXP, myVoyages, setMyVoyages }) => {
    const t = useTranslate();

    const [voyagesToView, setVoyagesToView] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const intervalRef = useRef(null); // Интервал для обновления каждые 5 сек
    const [loading, setLoading] = useState(false);
    const [levelupModal, setLevelupModal] = useState(null);

    useEffect(() => {
        if (window.tgID) {
            setDataLoaded(true);
        }
    }, [window.tgID]);

    useEffect(() => {
        const fetchData = async () => {
            if (dataLoaded) {
                const myVoyages_response = await getMyVoyages(window.tgID);
                
                const voyages = await Promise.all(myVoyages_response.map(async voyage => {
                    if (voyage.processed) {
                        const shipInfo = await getShipById(voyage.ship);
                        return { voyage, shipInfo };
                    }
                    return null;
                }));

                setVoyagesToView(voyages.filter(modal => modal !== null));
            }
        };
    
        if (dataLoaded) {
            fetchData();
        }
    
        // Устанавливаем интервал только если onVoyagePage равно true
        if (onVoyagePage) {
            intervalRef.current = setInterval(fetchData, 5000);
        }
    
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [dataLoaded, onVoyagePage]);

    const VoyageEndFunc = async () => {
        setLoading(true);

        const voyages = voyagesToView.map(voyage => voyage.voyage.id);

        const dataEndVoyage = await endVoyage(voyages); // Отправить запрос на удаление рейсов (обратно получить инфу для обновления ресурсов и опыта И ИНФУ ПО ТВОИМ ОСТАВШИМСЯ АКТИВНЫМ РЕЙСАМ)

        // Тут обновить, что надо (ресурсы, опыт, уровень)
        let nowLvl = Math.floor((-1 + Math.sqrt(1 + (8 * dataEndVoyage.xp) / 10)) / 2) + 1;

        if (nowLvl > window.lvl) { 
            setXP(dataEndVoyage.xp)
            setLVL(nowLvl);
            setLevelupModal(nowLvl);
        }

        window.resources = dataEndVoyage.resources;
        window.lvl = dataEndVoyage.lvl;
        window.xp = dataEndVoyage.xp;

        setVoyagesToView([]);

        if (onVoyagePage) {
            const updatedVoyages = myVoyages.filter(voyage => !voyages.includes(voyage.id));
            setMyVoyages(updatedVoyages);
        }

        setLoading(false);
    };

    return (
        <>
            {levelupModal && <LevelupModal levelupModal={levelupModal} setLevelupModal={setLevelupModal} />}
            
            {voyagesToView.length !== 0 &&
                ReactDOM.createPortal(
                    <div className="end-voyage-overlay">
                        <div className="end-voyage">
                            <div className="end-voyage-title">{t('end-voyage-title')}</div>

                            <div className="end-voyages-box">
                                {voyagesToView.map(voyage => (

                                    <div key={voyage.voyage.id} className="voyage-box">
                                        <div className="country-name">{t(voyage.voyage.country)}</div>

                                        <div className="voyage-info">

                                            <div className="ship-box">
                                                <img src={shipImages[voyage.shipInfo.type]} alt="Selected Ship" />
                                                <div className="name">{voyage.shipInfo.name}</div>
                                                <div className="lvl">{t('lvl')} {voyage.shipInfo.lvl}</div>
                                                <div className="capacity">
                                                    {getShipCapacity(voyage.shipInfo.type, voyage.shipInfo.lvl)}
                                                    <img src={capacityImage} alt='Capacity'/>
                                                </div>
                                            </div>

                                            <div className="conts-res-info">
                                                <div className="conts-box">
                                                    {voyage.voyage.conts.map((count, index) => {
                                                        if (count > 0) {
                                                            const type = Object.keys(containerImages)[index];
                                                            return (
                                                                <div key={type} className="cont-item">
                                                                    <div className={`cont-image ${type === 'silver-cont' && 'silver'}`}>
                                                                        {<img src={containerImages[type]} alt={type} />}
                                                                    </div>
                                                                    <div className="amount">{count}</div>
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </div>

                                                <div className="receive-box">
                                                    {Object.entries(voyage.voyage.resources).map(([resourceId, resourceData]) => (
                                                        <div key={resourceId} className="resource-item">
                                                            <div className="resource-img-box">
                                                                <img src={resourceImages[resourceId]} alt={`Resource ${resourceId}`} />
                                                            </div>
                                                            <div className="resource-amount">
                                                                <span>{format(resourceData.random)}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="resource-item">
                                                        <div className="resource-img-box">
                                                            <img src={XpImage} alt='XP' />
                                                        </div>
                                                        <div className="resource-amount">
                                                            <span>{voyage.voyage.xp}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                ))}
                            </div>

                            <button className={`modal-end-voyage-ok ${(loading) ? 'disabled' : ''}` } onClick={() => VoyageEndFunc()}>
                                {loading ? (
                                    <div className="loading-animation">
                                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                ) : 
                                    <span>{t('ok')}</span>
                                }
                            </button>
  
                        </div>
                    </div>,
                    document.body
                )
            }
        </>
    );
};

export default VoyageEnd;