import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import './sold.scss';
import { useTranslate } from '../translate/translate';

import { getSoldLots, deleteSoldLots } from '../requests';

import { format } from '../format-number';
import TonImage from '../market/ton.png';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

import Component1Image from '../components/1.png';
import Component2Image from '../components/2.png';
import Component3Image from '../components/3.png';
import Component4Image from '../components/4.png';
import Component5Image from '../components/5.png';
import Component6Image from '../components/6.png';
import Component7Image from '../components/7.png';
import Component8Image from '../components/8.png';
import Component9Image from '../components/9.png';
import Component10Image from '../components/10.png';
import Component11Image from '../components/11.png';
import Component12Image from '../components/12.png';
import Component13Image from '../components/13.png';
import Component14Image from '../components/14.png';
import Component15Image from '../components/15.png';
import Component16Image from '../components/16.png';
import Component17Image from '../components/17.png';
import Component18Image from '../components/18.png';
import Component19Image from '../components/19.png';
import Component20Image from '../components/20.png';
import Component21Image from '../components/21.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const Sold = () => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [soldLots, setSoldLots] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (window.tgID) {
                const soldLotsRes = await getSoldLots(window.tgID);
                if (soldLotsRes.length > 0) {
                    setSoldLots(soldLotsRes);
                    setShowModal(true);
                }
            }
        };
        fetchData();
    }, []);

    const deleteLotsFunc = async () => {
        setLoading(true);
        try {
            const lotIds = soldLots.map(lot => lot.id);
            await deleteSoldLots(lotIds);
            setSoldLots([]);
            setShowModal(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {showModal ? (
                ReactDOM.createPortal(
                    <div className="modal-sold-overlay">
                        <div className="modal-sold">
                            <span className="modal-sold-title">{t("sold-title")}</span>
    
                            <div className="modal-sold-lots">
                                {soldLots.map(lot => (
                                    <div key={lot.id} className="modal-sold-lot">
                                        <div className="modal-sold-lot-img">
                                            <img src={lot.type === 'resources' ? resourceImages[lot.number] : componentImages[lot.number]} alt={`Lot ${lot.id}`} />
                                        </div>
                                        <div className="modal-sold-lot-amount">
                                            <span>{format(lot.value)}</span>
                                        </div>
                                        <div className="modal-sold-lot-price-box">
                                            <span>за {lot.price}</span>
                                            <img src={TonImage} alt="TON" />
                                        </div>
                                    </div>
                                ))}
                            </div>
    
                            <button className="modal-sold-ok" onClick={deleteLotsFunc}>{t("ok")}</button>
                        </div>
                    </div>,
                    document.body
                )
            ) : 
            null
            }
        </>
    );
};

export default Sold;